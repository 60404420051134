<template>
  <div class="sidebar__padding">
    <div class="sidebar">
      <div class="brand sidebar-item">
        <button class="mobile-nav-btn btn btn-sm ti ti-arrow-left hide-on-desktop" @click="toggleMobileNav"></button>
        <img class="brand__logo sidebar-item__icon hide-on-mobile" :src="providerLogoUrl" alt="Logo" />
        <span class="brand__name sidebar-item__name">HCMS</span>
      </div>
      <SidebarItem v-for="link in sidebarLinks" :key="link.name" :link="link"> </SidebarItem>
      <div class="sidebar__click-capture" @click="toggleMobileNav"></div>
    </div>
  </div>
</template>
<script>
import sidebarLinks from '@/sidebarLinks';
import { PROVIDER_CONFIG } from 'hcms-constants/CONFIG_TYPES';
import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';
import SidebarItem from './SidebarItem.vue';

export default {
  name: 'Sidebar',
  components: {
    SidebarItem,
  },
  computed: {
    providerLogoUrl() {
      const providerConfig = this.$store.state.config[PROVIDER_CONFIG];
      const logoDocId = providerConfig.getValue('provider_logo');
      if (!logoDocId) {
        return '';
      }
      return `${SERVER_ADDRESS}/api/attachments/${logoDocId}/download`;
    },

    sidebarLinks() {
      const hasPermission = (link) => this.$store.getters.hasRouteAccess(link.path.name);

      const filteredLinks = [];

      sidebarLinks.forEach((link) => {
        if (link.children) {
          const filteredChildren = link.children.filter(hasPermission);
          if (filteredChildren.length) {
            filteredLinks.push({
              ...link,
              children: filteredChildren,
            });
          }
        } else if (hasPermission(link)) {
          filteredLinks.push(link);
        }
      });

      return filteredLinks;
    },
  },
  methods: {
    toggleMobileNav() {
      this.$emit('toggle-mobile-nav', false);
    },
  },
};
</script>
