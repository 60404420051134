<template>
  <div class="loading-overlay">
    <svg viewBox="25 25 50 50" class="loader">
      <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
    </svg>
    <div v-if="reason" class="reason" v-html="reason"></div>
  </div>
</template>
<script>
export default {
  name: 'CardOverlay',
  props: {
    reason: {
      type: String,
      default: '',
    },
  },
};
</script>
