import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const defaultState = {
  lastReport: '',
  bookingGenerationStatus: {
    running: false,
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
