<template>
  <div class="upload-file">
    <button
      class="btn btn-primary btn-simple upload-btn"
      @click.prevent="selectFile()"
      v-if="!hideBtn"
      v-loading="loading && !uploadDone"
      :class="injectedClass">
      <span v-html="buttonLabel"></span>
    </button>

    <div v-if="showFileList">
      <div v-if="fileList.length > 0" class="file-list">
        <div v-for="file in fileList" :key="file.attachmentId" class="file-list__file">
          <a target="_blank" :href="file.url" class="file-list__filename">
            {{ file.name }}
          </a>
          <a
            @click="deleteAttachment(file.attachmentId)"
            v-if="!isDisabled"
            class="ti ti-close text-muted file-list__delete"></a>
        </div>
      </div>
      <div class="placeholder-message" v-else-if="isDisabled">No Attachments</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { pickFile } from 'js-pick-file';
import { get as getAttachment, post as postAttachment } from 'hcms-transforms/attachment';

export default {
  name: 'UploadFile',
  model: {
    prop: 'value',
  },
  inject: {
    elForm: {
      default: () => ({}),
    },
  },
  props: {
    userName: {
      type: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    // type of attachment
    type: {
      type: String,
      default: 'Other',
    },
    multiple: {
      type: Boolean,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    isImage: {
      type: Boolean,
    },
    limit: {
      type: Number,
      default: 5,
    },
  },

  constants: {
    notificationTimeout: 3000,
  },

  data() {
    return {
      fileList: [],
      uploadDone: false,
      loading: false,
    };
  },

  computed: {
    allowedFileTypes() {
      const fileTypes = ['.jpg', '.jpeg', '.png'];
      if (!this.isImage) {
        fileTypes.push('.pdf');
      }
      return fileTypes.toString();
    },
    isDisabled() {
      if (this.disabled !== undefined) {
        return this.disabled;
      }

      return this.elForm ? this.elForm.disabled : false;
    },
    hideBtn() {
      if (this.isDisabled) {
        return true;
      }
      if (!this.showFileList) {
        return false;
      }
      return !this.multiple && this.fileList.length === 1;
    },
    buttonLabel() {
      if (this.uploadDone) {
        return '<i class="ti ti-check"></i> Uploaded';
      }

      return '<i class="ti ti-upload"></i> Upload';
    },
    injectedClass() {
      return `${this.uploadDone ? 'btn-success' : ''} ${this.elForm && this.elForm.size === 'small' ? 'btn-sm' : ''}`;
    },
    model: {
      get() {
        return this.value;
      },
      set(fileList) {
        this.$emit('input', fileList);
      },
    },

    pickerOptions() {
      const options = {
        accept: this.allowedFileTypes,
      };
      if (this.multiple) {
        options.multiple = true;
      }
      return options;
    },

    MAX_FILE_SIZE() {
      return this.$store.state.config.DOCUMENT_DETAILS
        ? this.$store.state.config.DOCUMENT_DETAILS.getValue('max_size') * 1024 * 1024
        : 0;
    },
  },

  methods: {
    reset() {
      this.uploadDone = false;
    },

    async selectFile() {
      try {
        const files = await pickFile(this.pickerOptions);
        Array.prototype.forEach.call(files, (file) => {
          if (file.size > this.MAX_FILE_SIZE) {
            throw new Error(
              `File should be <${(this.MAX_FILE_SIZE / 1024 / 1024).toFixed(1)}MB. Attached file is ${(
                file.size /
                1024 /
                1024
              ).toFixed(1)}MB`,
            );
          }
        });
        this.upload(files);
      } catch (err) {
        if (err instanceof FileList) {
          console.debug('File picker closed without picking any files');
        } else {
          this.$notify(err, 'error');
        }
        // eslint-disable-next-line no-console
      }
    },

    async upload(files) {
      if (files.length < 1) return;

      if (files.length + this.fileList.length > this.limit) {
        this.$notify(`Cannot upload more than ${this.limit} files in total`, 'error');
        return;
      }

      const awaits = Array.from(files).map((file) =>
        postAttachment(this.userName, {
          name: file.name,
          fileData: file,
          note: this.type,
        }),
      );

      this.uploadDone = false;
      this.loading = true;
      try {
        const fileList = await axios.all(awaits);
        this.setFileList(fileList, this.multiple);
        this.$notify('File is uploaded', 'success');

        this.uploadDone = true;
        await this.sleep(this.notificationTimeout);
        this.uploadDone = false;
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    async deleteAttachment(attachmentId) {
      if (attachmentId) {
        try {
          const fileList = this.fileList.filter((item) => item.attachmentId !== attachmentId);
          this.setFileList(fileList);
        } catch (err) {
          this.$notify(err, 'error');
        }
      }
    },

    getFileIds(fileList) {
      return fileList.map((file) => file.attachmentId);
    },

    isArraySame(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }
      const same = array1.map((elem1, index) => {
        const elem2 = array2[index];
        return elem1 === elem2;
      });

      if (same.indexOf(false) !== -1) {
        return false;
      }

      return true;
    },

    setFileList(fileList, appendMode = false) {
      if (appendMode) {
        this.fileList = this.fileList.concat(fileList);
      } else {
        this.fileList = fileList;
      }
      this.model = this.getFileIds(this.fileList);
      this.$emit('fileChanged', this.fileList);
    },

    async getFileListData() {
      if (this.model.length < 1) {
        this.setFileList([]);
        return;
      }

      try {
        const fileList = await Promise.all(this.model.map(getAttachment));
        this.setFileList(fileList);
      } catch (err) {
        this.$notify(err, 'error');
      }
    },
  },

  watch: {
    model: {
      handler(modelVal) {
        const currentFileIds = this.getFileIds(this.fileList);
        if (!this.isArraySame(modelVal, currentFileIds)) {
          this.getFileListData();
        }
      },
    },
  },

  created() {
    this.$store.dispatch('getConfig', ['DOCUMENT_DETAILS']);
    this.reset();
    this.getFileListData();
  },
};
</script>
<style lang="scss">
.upload-file {
  .upload-btn {
    width: 100%;
    max-width: 128px;
  }

  .el-form-item.is-error {
    .upload-btn {
      border-color: #ef5350;
      color: #ef5350;
    }
  }

  .placeholder-message {
    font-size: 13px;
    color: #c0c4cc;
  }
}
</style>
