import axios from 'axios';

import BE_CLASSES from 'hcms-constants/BE_CLASSES';

import { classNameToRoute } from 'utils/routeUtils';
import { classNameToModal } from 'utils/modalUtils';

function getModalObject(notification) {
  const { userName } = notification;
  const { type, id } = notification.action;

  return classNameToModal(type, userName, id);
}

function getRouteObject(notification) {
  const { userName } = notification;
  const { type } = notification.action;

  const routeName = classNameToRoute(type);

  return (
    routeName && {
      name: routeName,
      params: { userName },
    }
  );
}

function showModal(notification) {
  const modalObject = getModalObject(notification);
  return this.$showModal(modalObject);
}

function reroute(notification) {
  const routeObject = getRouteObject(notification);
  const routeData = this.$router.resolve(routeObject);

  window.open(routeData.href, '_blank');
}

const ACTION_DICT_ENTRIES = Object.values(BE_CLASSES).map((type) => {
  const mockNotification = { action: { type } };
  return [
    type,
    {
      route: Boolean(getRouteObject(mockNotification)),
      modal: Boolean(getModalObject(mockNotification)),
    },
  ];
});

const ACTION_DICT = Object.fromEntries(ACTION_DICT_ENTRIES);

function markAsSeen(notification) {
  const url = `api/notification/${notification.id}/seen`;
  return axios.put(url, {});
}

function markAsUnseen(notification) {
  const url = `api/notification/${notification.id}/unsee`;
  return axios.put(url, {});
}

async function openDetails(notification, type) {
  const hasActions = ACTION_DICT[notification.action.type];

  if (!hasActions) {
    throw new Error(`Unrecognized notification type '${notification.action.type}' of notification ${notification.id}`);
  }

  if (type && !hasActions[type]) {
    throw new Error(`Incorrect action for notification ${notification.id} of type '${notification.action.type}'`);
  }

  if (type === 'modal' || (!type && hasActions.modal)) {
    return showModal.call(this, notification);
  }

  return reroute.call(this, notification);
}

export { ACTION_DICT, getModalObject, getRouteObject, markAsSeen, markAsUnseen, openDetails };
