import {
  attachmentTypeUtils,
  careWorkerStatusUtils,
  contractTypeUtils,
  notificationPurposeUtils,
  serviceUserStatusUtils,
} from 'hcms-mc-utils';

import Offline from 'lib/paper-dashboard/views/Offline.vue';
import Router from 'vue-router';
import Vue from 'vue';
import { cleanObject } from 'global-utils';
import { currentUser } from 'auth-utils';
import { getAll as getCws } from 'hcms-transforms/cw';
import { getAll as getSus } from 'hcms-transforms/su';
import moment from 'moment';
import { notify } from 'utils/notify';
import { roleUtils } from 'hcms-const-utils';
import store from 'store';

Vue.use(Router);

const { FIXED } = contractTypeUtils.DICT;
const { PART_TIME } = contractTypeUtils.DICT;
const { FLEXIBLE } = contractTypeUtils.DICT;

const NOTIFICATION = notificationPurposeUtils.DICT.INFO;
const ACTION = notificationPurposeUtils.DICT.TASK;

const cwListParams = {
  contract_type: [FIXED, PART_TIME, FLEXIBLE].toString(),
  cw_status: ['Suspended', 'Active', 'On Leave', 'On Training']
    .map(careWorkerStatusUtils.toValue, careWorkerStatusUtils)
    .toString(),
  limit: 1,
  sort: 'forename,surname',
};

// GeneralViews
const NotFound = () => import(/* webpackChunkName:"NotFound" */ 'lib/paper-dashboard/views/NotFoundPage.vue');
// const Wip = () => import(/* webpackChunkName:"Wip" */'views/Wip.vue');
const OverviewCtr = () => import(/* webpackChunkName:"OverviewCtr" */ 'views/Overview/OverviewCtr.vue');
const OverviewFin = () => import(/* webpackChunkName:"OverviewFin" */ 'views/Overview/OverviewFin.vue');
const OverviewHr = () => import(/* webpackChunkName:"OverviewHr" */ 'views/Overview/OverviewHr.vue');
const OverviewMgt = () => import(/* webpackChunkName:"OverviewMgt" */ 'views/Overview/OverviewMgt.vue');
const OverviewSup = () => import(/* webpackChunkName:"OverviewSup" */ 'views/Overview/OverviewSup.vue');
const Notifications = () => import(/* webpackChunkName:"Notifications" */ 'views/Notifications.vue');

// Care Worker
const CareWorkerList = () => import(/* webpackChunkName:"CareWorkerList" */ 'views/CareWorker/CareWorkerList.vue');
const CareWorkerDetails = () =>
  import(/* webpackChunkName:"CareWorkerDetails" */ 'views/CareWorker/CareWorkerDetails.vue');

const CareWorkerOverview = () =>
  import(/* webpackChunkName:"CareWorkerOverview" */ 'views/CareWorker/Overview/index.vue');
const CareWorkerAppointmentDetails = () =>
  import(/* webpackChunkName:"CareWorkerAppointmentDetails" */ 'views/CareWorker/AppointmentDetails/index.vue');
const CareWorkerPersonalInfo = () =>
  import(/* webpackChunkName:"CareWorkerPersonalInfo" */ 'views/CareWorker/PersonalInfo/index.vue');
const CareWorkerSkills = () => import(/* webpackChunkName:"CareWorkerSkills" */ 'views/CareWorker/Skills/index.vue');
const CareWorkerLeave = () => import(/* webpackChunkName:"CareWorkerLeave" */ 'views/CareWorker/Leave/index.vue');
const CareWorkerServiceDetails = () =>
  import(/* webpackChunkName:"CareWorkerServiceDetails" */ 'views/CareWorker/ServiceDetails/index.vue');
const CareWorkerPayslips = () => import(/* webpackChunkName:"CareWorkerPayslips" */ 'views/CareWorker/Payslips.vue');
const AttachmentList = () => import(/* webpackChunkName:"AttachmentList" */ 'views/Common/Cards/AttachmentList.vue');

// Service User
const ServiceUserList = () => import(/* webpackChunkName:"ServiceUserList" */ 'views/ServiceUser/ServiceUserList.vue');
const ServiceUserPricingPlans = () =>
  import(/* webpackChunkName:"ServiceUserPricingPlans" */ 'views/ServiceUser/PricingPlans.vue');
const ServiceUserInvoices = () => import(/* webpackChunkName:"ServiceUserInvoices" */ 'views/ServiceUser/Invoices.vue');
const ServiceUserDetails = () =>
  import(/* webpackChunkName:"ServiceUserDetails" */ 'views/ServiceUser/ServiceUserDetails.vue');

const ServiceUserOverview = () =>
  import(/* webpackChunkName:"ServiceUserOverview" */ 'views/ServiceUser/Overview/index.vue');
const ServiceUserPersonalInfo = () =>
  import(/* webpackChunkName:"ServiceUserPersonalInfo" */ 'views/ServiceUser/PersonalInfo/index.vue');
const ServiceUserObservations = () =>
  import(/* webpackChunkName:"ServiceUserObservations" */ 'views/ServiceUser/Observations/index.vue');
const ServiceUserMedical = () =>
  import(/* webpackChunkName:"ServiceUserMedical" */ 'views/ServiceUser/Medical/index.vue');
const ServiceUserCareDetails = () =>
  import(/* webpackChunkName:"ServiceUserCareDetails" */ 'views/ServiceUser/CareDetails/index.vue');

const StaffList = () => import(/* webpackChunkName:"StaffList" */ 'views/Staff/List.vue');
const StaffDetails = () => import(/* webpackChunkName:"StaffDetails" */ 'views/Staff/Details/index.vue');
const StaffRecruitment = () => import(/* webpackChunkName:"StaffRecruitment" */ 'views/Staff/Details/Recruitment.vue');
const StaffLeave = () => import(/* webpackChunkName:"StaffLeave" */ 'views/Staff/Details/Leave.vue');
const StaffPersonalInfo = () =>
  import(/* webpackChunkName:"StaffPersonalInfo" */ 'views/Staff/Details/PersonalInfo.vue');
const StaffVisits = () => import(/* webpackChunkName:"StaffVisits" */ 'views/Staff/Details/Visits.vue');

// Booking
const BookingListView = () => import(/* webpackChunkName:"BookingListView" */ 'views/Common/Cards/BookingList.vue');
const BookingBlockView = () => import(/* webpackChunkName:"BookingBlockView" */ 'views/Booking/BlockView.vue');
const BookingCompareView = () => import(/* webpackChunkName:"BookingCompareView" */ 'views/Booking/CompareView.vue');
const BookingAllocation = () => import(/* webpackChunkName:"BookingAllocation" */ 'views/Booking/Allocation.vue');

const BookingGrid = () => import(/* webpackChunkName:"BookingGrid" */ 'views/Common/Cards/BookingGrid.vue');

const FormEntries = () => import(/* webpackChunkName:"FormEntries" */ 'views/Forms/FormEntries.vue');
const FormTemplates = () => import(/* webpackChunkName:"FormTemplates" */ 'views/Forms/FormTemplates.vue');
const FormRequests = () => import(/* webpackChunkName:"FormRequests" */ 'views/Forms/FormRequests.vue');

const Profile = () => import(/* webpackChunkName:"Profile" */ 'views/Profile/index.vue');
const StaffOverview = () => import(/* webpackChunkName:"StaffOverview" */ 'views/StaffOverview.vue');

// * IIFE
const Overview = (() => {
  switch (currentUser.role) {
    case roleUtils.DICT.CTR:
      return OverviewCtr;
    case roleUtils.DICT.FIN:
      return OverviewFin;
    case roleUtils.DICT.HR:
      return OverviewHr;
    case roleUtils.DICT.MGT:
      return OverviewMgt;
    case roleUtils.DICT.SUP:
      return OverviewSup;
    default:
      return null;
  }
})();

const overview = {
  path: '/overview',
  name: 'Overview',
  component: Overview,
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const notifications = {
  path: '/notifications',
  name: 'Notifications',
  component: Notifications,
  props: () => ({
    queryParams: { purpose: NOTIFICATION },
    name: 'Notifications',
    maxHeight: document.body.scrollHeight - 112,
    showTitle: false,
  }),
  meta: { key: 'Notifications' },
};

const actions = {
  path: '/actions',
  name: 'Actions',
  component: Notifications,
  props: () => ({
    queryParams: { purpose: ACTION },
    name: 'Actions',
    maxHeight: document.body.scrollHeight - 112,
    showTitle: false,
  }),
  meta: { key: 'Actions' },
};

const staffList = {
  path: '/staff/list',
  name: 'Staff List',
  component: StaffList,
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const staffDetails = {
  path: '/staff/details',
  name: 'Staff Details',
  component: StaffDetails,
  async beforeEnter(to, from, next) {
    const defaultPath = 'Staff Recruitment';

    if (to.name !== 'Staff Details') {
      next();
      return;
    }

    if (to.params.userName) {
      next({
        name: defaultPath,
        params: to.params,
      });
      return;
    }

    if (store.getters.staff.length === 0) {
      next();
      return;
    }

    const firstUser = store.getters.staff[0];

    if (!firstUser) {
      notify('No active staff in the system.', 'error');
      next(false);
      return;
    }

    next({
      name: defaultPath,
      params: {
        userName: firstUser.userName,
      },
    });
  },
  children: [
    {
      path: ':userName/personal-info',
      name: 'Staff Personal Info',
      component: StaffPersonalInfo,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/leave',
      name: 'Staff Leave',
      component: StaffLeave,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/recruitment',
      name: 'Staff Recruitment',
      component: StaffRecruitment,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/attachments',
      name: 'Staff Attachments',
      component: AttachmentList,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/visits',
      name: 'Staff Visits',
      component: StaffVisits,
      props: (route) => ({
        userName: route.params.userName,
        maxHeight: document.body.scrollHeight - 217,
      }),
    },
  ],
};

const cwList = {
  path: '/cw/list',
  name: 'Care Worker List',
  component: CareWorkerList,
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const cwDetails = {
  path: '/cw/details',
  name: 'Care Worker Details',
  async beforeEnter(to, from, next) {
    const defaultPath = 'CW Details Redirect';

    if (to.name !== 'Care Worker Details') {
      next();
      return;
    }

    if (to.params.userName) {
      next({
        name: defaultPath,
        params: to.params,
      });
      return;
    }

    const cws = await getCws(cwListParams);

    const firstUser = cws[0];

    if (!firstUser) {
      notify('No active CWs in the system.', 'error');
      next(false);
      return;
    }

    next({
      name: defaultPath,
      params: {
        userName: firstUser.userName,
      },
    });
  },
  component: CareWorkerDetails,
  children: [
    {
      path: ':userName',
      name: 'CW Details Redirect',
      redirect: {
        name: store.getters.hasRouteAccess('Care Worker Overview') ? 'Care Worker Overview' : 'Care Worker Roster',
      },
    },
    {
      path: ':userName/overview',
      name: 'Care Worker Overview',
      component: CareWorkerOverview,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/appointment-details',
      name: 'Care Worker Recruitment',
      component: CareWorkerAppointmentDetails,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/personal-info',
      name: 'Care Worker Personal Info',
      component: CareWorkerPersonalInfo,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/skills',
      name: 'Care Worker Skills',
      component: CareWorkerSkills,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/leave',
      name: 'Care Worker Leave',
      component: CareWorkerLeave,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/service-details',
      name: 'Care Worker Service',
      component: CareWorkerServiceDetails,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/roster',
      name: 'Care Worker Roster',
      component: BookingGrid,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/attachments',
      name: 'Care Worker Attachments',
      component: AttachmentList,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/payslips',
      name: 'Care Worker Payslips',
      component: AttachmentList,
      props: (route) => ({
        userName: route.params.userName,
        title: 'Payslips',
        attachmentTypes: [attachmentTypeUtils.DICT.PAYSLIP],
      }),
    },
  ],
};

const cwPayslips = {
  path: '/cw/payslips',
  name: 'Manage Payslips',
  component: CareWorkerPayslips,
  props: (route) => ({
    maxHeight: document.body.scrollHeight - 112,
    baseQuery: cleanObject({
      cwUserNames: route.query.cwUserNames ? route.query.cwUserNames.split(',') : undefined,
      startDate: route.query.startDate ? moment(route.query.startDate) : undefined,
      endDate: route.query.endDate ? moment(route.query.endDate) : undefined,
    }),
  }),
};

const suList = {
  path: '/su/list',
  name: 'Service User List',
  component: ServiceUserList,
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const suDetails = {
  path: '/su/details',
  name: 'Service User Details',
  component: ServiceUserDetails,
  async beforeEnter(to, from, next) {
    const defaultPath = 'SU Details Redirect';

    if (to.name !== 'Service User Details') {
      next();
      return;
    }

    if (to.params.userName) {
      next({
        name: defaultPath,
        params: to.params,
      });
      return;
    }

    const serviceUsers = await getSus({
      status: [serviceUserStatusUtils.DICT.ACTIVE, serviceUserStatusUtils.DICT.SUSPENDED].toString(),
      sort: 'forename,surname',
    });

    const firstUser = serviceUsers[0];

    if (!firstUser) {
      notify('No active SUs in the system.', 'error');
      next(false);
      return;
    }

    next({
      name: defaultPath,
      params: {
        userName: firstUser.userName,
      },
    });
  },
  children: [
    {
      path: ':userName',
      name: 'SU Details Redirect',
      redirect: {
        name: store.getters.hasRouteAccess('Service User Overview') ? 'Service User Overview' : 'Service User Bookings',
      },
    },
    {
      path: ':userName/overview',
      name: 'Service User Overview',
      component: ServiceUserOverview,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/personal-info',
      name: 'Service User Personal Info',
      component: ServiceUserPersonalInfo,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/bookings',
      name: 'Service User Bookings',
      component: BookingGrid,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/observations',
      name: 'Service User Observations',
      component: ServiceUserObservations,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/medical',
      name: 'Service User Medical',
      component: ServiceUserMedical,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/care-details',
      name: 'Service User Care',
      component: ServiceUserCareDetails,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/attachments',
      name: 'Service User Attachments',
      component: AttachmentList,
      props: (route) => ({
        userName: route.params.userName,
      }),
    },
    {
      path: ':userName/invoices',
      name: 'Service User Invoices',
      component: AttachmentList,
      props: (route) => ({
        userName: route.params.userName,
        title: 'Invoices',
        attachmentTypes: [attachmentTypeUtils.DICT.INVOICE],
      }),
    },
  ],
};

const suPricing = {
  path: '/su/pricing-plans',
  name: 'Service User Pricing Plans',
  component: ServiceUserPricingPlans,
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const suInvoicing = {
  path: '/su/invoices',
  name: 'Manage Invoices',
  component: ServiceUserInvoices,
  props: (route) => ({
    maxHeight: document.body.scrollHeight - 112,
    baseQuery: cleanObject({
      suUserNames: route.query.suUserNames ? route.query.suUserNames.split(',') : undefined,
      startDate: route.query.startDate ? moment(route.query.startDate) : undefined,
      endDate: route.query.endDate ? moment(route.query.endDate) : undefined,
    }),
  }),
};

const bookingBlockView = {
  path: '/booking/block-view',
  name: 'Bookings Block View',
  component: BookingBlockView,
};

const bookingCompareView = {
  path: '/booking/compare',
  name: 'Bookings Compare View',
  component: BookingCompareView,
};

const bookingListView = {
  path: '/booking/list-view',
  name: 'Bookings List View',
  component: BookingListView,
  meta: {
    key: 'Bookings List View',
  },
  props: () => ({
    showReport: true,
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const bookingAllocation = {
  path: '/booking/allocation',
  name: 'AI Scheduler',
  component: BookingAllocation,
  meta: {
    navbarClass: 'warning',
  },
};

const formTemplates = {
  path: '/form/templates',
  name: 'Form Templates',
  component: FormTemplates,
};

const allEntries = {
  path: '/form/submissions',
  name: 'All Form Submissions',
  component: FormEntries,
};

const formRequests = {
  path: '/form/requests',
  name: 'Form Submission Requests',
  component: FormRequests,
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const formEntries = {
  path: '/form/:formId/submissions',
  name: 'Form Submissions',
  component: FormEntries,
  props: (route) => ({
    formId: Number(route.params.formId),
  }),
};

const profileMenu = {
  name: 'My Profile',
  path: '/profile',
  component: Profile,
};

const notFound = {
  path: '/not-found',
  name: 'Not Found',
  component: NotFound,
};

const offline = {
  path: '/offline',
  name: 'Offline',
  component: Offline,
  meta: {
    disableLayout: true,
  },
};

const consumables = {
  path: '/vm/consumables',
  name: 'Consumables',
  component: () => import(/* webpackChunkName:"Consumables" */ 'views/Consumables.vue'),
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const vmBookings = {
  path: '/vm/bookings',
  name: 'Visited Bookings',
  component: BookingListView,
  meta: {
    key: 'Visited Bookings',
  },
  props: () => ({
    showReport: true,
    maxHeight: document.body.scrollHeight - 112,
    isVm: true,
    PRESET_TYPE: 'VM_BOOKINGS',
  }),
};

const vmRegistration = {
  path: '/vm/registration',
  name: 'Registration',
  component: () => import(/* webpackChunkName:"VmRegistration" */ 'views/VmRegistration.vue'),
  props: () => ({
    maxHeight: document.body.scrollHeight - 112,
  }),
};

const vmDefaultMessages = {
  path: '/vm/default-messages',
  name: 'Remarks',
  component: () => import(/* webpackChunkName:"VmDefaultMessages" */ 'views/VmDefaultMessages.vue'),
};

const mainStaffRoutes = [
  {
    path: '/',
    redirect: () => ({
      name: store.getters.hasRouteAccess('Overview') ? 'Overview' : 'Actions',
    }),
  },
  overview,
  notifications,
  actions,
  staffList,
  staffDetails,
  cwList,
  cwDetails,
  cwPayslips,
  suList,
  suDetails,
  suPricing,
  suInvoicing,
  bookingBlockView,
  bookingCompareView,
  bookingListView,
  bookingAllocation,
  formTemplates,
  allEntries,
  formRequests,
  formEntries,
  consumables,
  vmRegistration,
  vmDefaultMessages,
  vmBookings,
  profileMenu,
  notFound,
  offline,
  {
    path: '*',
    redirect: {
      name: 'Not Found',
    },
  },
];

const otherStaffRoutes = [
  {
    path: '/',
    name: 'My Profile',
    component: StaffOverview,
    meta: {
      hideSidebar: true,
    },
  },
  {
    path: '*',
    redirect: {
      name: 'My Profile',
    },
  },
];

const isMainStaff = currentUser?.roleConst?.meta?.isStaff;
const routes = isMainStaff ? mainStaffRoutes : otherStaffRoutes;

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.

 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
  };

  * */

const router = new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  if (!store.getters.hasRouteAccess(to.name)) {
    next({
      name: 'Not Found',
    });
    return;
  }

  next();
});

export default router;
