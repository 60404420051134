import BE_CLASSES from 'hcms-constants/BE_CLASSES';
import { hasAccess } from 'utils/accessUtils';

const hasStaffAccess = hasAccess('staff');
const MY_PROFILE = 'My Profile';

const classNameToRoute = (className) => {
  switch (className) {
    case BE_CLASSES.STAFF_PERSONAL_INFO:
      return hasStaffAccess ? 'Staff Personal Info' : MY_PROFILE;

    case BE_CLASSES.STAFF_LEAVE:
      return hasStaffAccess ? 'Staff Leave' : MY_PROFILE;

    case BE_CLASSES.STAFF_EMPLOYMENT:
    case BE_CLASSES.STAFF_SUSPEND:
      return hasStaffAccess ? 'Staff Recruitment' : MY_PROFILE;

    case BE_CLASSES.CW_LEAVE:
      return 'Care Worker Leave';

    case BE_CLASSES.CW_PREFERENCE:
    case BE_CLASSES.CW_AVAILABILITY:
      return 'Care Worker Service';

    case BE_CLASSES.CW_CERTIFICATE:
    case BE_CLASSES.CW_TRAINING:
      return 'Care Worker Skills';

    case BE_CLASSES.CW_PERSONAL_INFO:
      return 'Care Worker Personal Info';

    case BE_CLASSES.CW_EMPLOYMENT:
    case BE_CLASSES.CW_SUSPEND:
    case BE_CLASSES.CW_TERMINATE:
      return 'Care Worker Recruitment';

    case BE_CLASSES.SU_CARE_PLAN:
    case BE_CLASSES.SU_SUSPEND:
      return 'Service User Care';

    case BE_CLASSES.SU_ADDRESS:
      return 'Service User Personal Info';

    case BE_CLASSES.MEDICATION:
    case BE_CLASSES.MEDICAL_CONDITION:
    case BE_CLASSES.MEDICATION_ISSUE_LOG:
      return 'Service User Medical';

    case BE_CLASSES.FORM_REQUEST:
      return 'Form Submission Requests';

    default:
      return false;
  }
};

export { classNameToRoute };
