import { getAll as getLocks, post as postLock } from 'hcms-transforms/data_lock';

import { DATA_LOCK } from 'hcms-constants/BE_CLASSES';
import { add as addHook, remove as removeHook } from 'mixins/DataHook';
import store from 'store';

import { toggleLock } from 'directives/Lock';
import { currentUser } from 'auth-utils';

const Plugin = {
  install(Vue) {
    /* eslint-disable no-underscore-dangle */
    Vue.mixin({
      data() {
        return {
          dataLockDetails: undefined,
          activeDataLock: undefined,
        };
      },
      methods: {
        obtainLock() {
          if (!this._lockIntervalId) {
            const callback = postLock.bind(this, this.dataLockDetails);
            callback();
            this._lockIntervalId = window.setInterval(callback, 5000);
          }
        },
        releaseLock() {
          window.clearInterval(this._lockIntervalId);
          this._lockIntervalId = null;
        },
        disableCard() {
          toggleLock(
            this.$el,
            `Already being edited by ${store.getters.getName(this.activeDataLock.lockedBy, { role: true })}`,
            'data-lock',
          );
        },
        enableCard() {
          toggleLock(this.$el, false, 'data-lock');
        },
        async checkActiveLock() {
          const activeDataLocks = await getLocks({
            user_name: this.dataLockDetails.userName,
            object_id: this.dataLockDetails.id,
            object_type: this.dataLockDetails.type,
            exclude_locked_by: currentUser.userName,
          });
          this.activeDataLock = activeDataLocks[0];
          if (this.activeDataLock) {
            this.disableCard();
          } else {
            this.enableCard();
          }
        },
        async checkEdit() {
          const isEdited = this[this.dataLockDetails.editedKey];
          if (isEdited) {
            this.obtainLock();
            this.activeDataLock = undefined;
          } else {
            this.releaseLock();
            await this.checkActiveLock();
          }
        },
        async toggleLockOnOpen() {
          await this.checkActiveLock();
          if (!this.activeDataLock) {
            this.obtainLock();
          }
        },
      },
      async mounted() {
        if (this.$options.dataLock && store.getters.isLiveMode) {
          const { idKey, type, editOnOpen } = this.$options.dataLock;
          const userNameKey = this.$options.dataLock.userNameKey || 'userName';
          const editedKey = this.$options.dataLock.editedKey || 'isEdited';
          const id = this[idKey];
          const userName = this[userNameKey];

          this.dataLockDetails = {
            idKey,
            userNameKey,
            editedKey,
            id,
            userName,
            type,
            editOnOpen,
          };
          if (editOnOpen) {
            this.toggleLockOnOpen();
            addHook(DATA_LOCK, this.toggleLockOnOpen);
          } else {
            this.$watch(editedKey, this.checkEdit, { immediate: true });
            addHook(DATA_LOCK, this.checkEdit);
          }
        }
      },
      destroyed() {
        if (this.dataLockDetails) {
          this.releaseLock();
          removeHook(DATA_LOCK, this.checkEdit);
          removeHook(DATA_LOCK, this.toggleLockOnOpen);
        }
      },
    });
    /* eslint-enable no-underscore-dangle */
  },
};

export { Plugin };

export default Plugin;
