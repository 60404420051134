import debounce from 'lodash.debounce';

const callbacks = {};

function removeHook(dataType, callback) {
  if (!Object.prototype.hasOwnProperty.call(callbacks, dataType)) {
    return;
  }

  callbacks[dataType] = callbacks[dataType].filter((hook) => hook !== callback);
}

function addHook(dataType, callback) {
  if (!Object.prototype.hasOwnProperty.call(callbacks, dataType)) {
    callbacks[dataType] = [];
  }
  callbacks[dataType].push(callback);
}

function emit(dataType, data) {
  if (!Object.prototype.hasOwnProperty.call(callbacks, dataType)) {
    return;
  }
  callbacks[dataType].forEach((callback) => {
    callback(data);
  });
}

const Plugin = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$dataHooks = {
      add: addHook,
      remove: removeHook,
      emit,
    };

    /* eslint-disable no-underscore-dangle */
    Vue.mixin({
      created() {
        const self = this;
        if (self.$options.dataHooks) {
          self._dataHooks = {};
          Object.keys(self.$options.dataHooks).forEach((dataType) => {
            self._dataHooks[dataType] = self.$options.dataHooks[dataType].bind(self);
            if (self._dataHooks[dataType].length === 0) {
              self._dataHooks[dataType] = debounce(self._dataHooks[dataType], 100);
            }
            addHook(dataType, self._dataHooks[dataType]);
          });
        }
      },
      destroyed() {
        const self = this;
        if (self.$options.dataHooks) {
          Object.keys(self._dataHooks).forEach((dataType) => {
            removeHook(dataType, self._dataHooks[dataType]);
          });
        }
      },
    });
    /* eslint-enable no-underscore-dangle */
  },
};

export { Plugin, addHook as add, removeHook as remove, emit };

export default Plugin;
