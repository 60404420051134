import Vue from 'vue';

import {
  Alert,
  Autocomplete,
  Avatar,
  Badge,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Input,
  Option,
  OptionGroup,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TableColumn,
  Tag,
  Tooltip,
} from 'element-ui';

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import ElementDefaults from './elementDefaults';
// Element UI components
Vue.use(Alert);
Vue.use(Autocomplete);
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(TableColumn);
Vue.use(Tag);
Vue.use(Tooltip);

Vue.component('CollapseTransition', CollapseTransition);

Vue.use(ElementDefaults);
