import axios from 'axios';
import { nullToEmpty } from 'global-utils';

function transformResponse(skill) {
  return nullToEmpty({
    skillTemplateId: skill.skill_template_id,
    skillName: skill.skill_name,
    isMandatory: skill.is_mandatory,
  });
}

async function getAll(params) {
  const url = 'api/skill';
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(skillTemplateId) {
  const url = `api/skill/${skillTemplateId}`;
  const response = await axios.get(url);

  return transformResponse(response.data.skill);
}

export { transformResponse, getAll, get };
