export default {
  install(Vue) {
    const { components } = Vue.options;
    const overrides = {
      ElAlert: {
        props: {
          closable: {
            type: Boolean,
            default: false,
          },
        },
        size: {
          type: String,
          default: 'small',
        },
      },

      ElBadge: {
        props: {
          type: {
            type: String,
          },
        },
      },

      ElForm: {
        props: {
          labelPosition: {
            type: String,
            default: 'top',
          },
          size: {
            type: String,
            default: 'small',
          },
        },
        methods: {
          handleDisabled() {
            if (this.disabled) {
              this.$el.className += ' el-form--disabled';
            } else {
              this.$el.className = this.$el.className.replace(' el-form--disabled', '');
            }
          },
          handleSize(newSize, oldSize) {
            this.$el.className = this.$el.className.replace(` el-form--${oldSize}`, '');
            this.$el.className += ` el-form--${newSize}`;
          },
        },
        watch: {
          disabled: 'handleDisabled',
          size: 'handleSize',
        },
        mounted() {
          this.handleDisabled();
          this.handleSize(this.size);
        },
      },
      ElCheckbox: {
        props: {
          size: {
            type: String,
            default: 'small',
          },
        },
      },
      ElInput: {
        props: {
          autosize: {
            type: Object,
            default: () => ({
              minRows: 1,
            }),
          },
          size: {
            type: String,
            default: 'small',
          },
        },
      },
      ElSelect: {
        props: {
          defaultFirstOption: {
            type: Boolean,
            default: true,
          },
          filterable: {
            type: Boolean,
            default: true,
          },
          collapseTags: {
            type: Boolean,
            default: true,
          },
          size: {
            type: String,
            default: 'small',
          },
        },
        watch: {
          disabled(value) {
            if (value) {
              this.$el.className += ' is-disabled';
            } else {
              this.$el.className = this.$el.className.replace(' is-disabled', '');
            }
          },
        },
        mounted() {
          if (this.disabled) {
            this.$el.className += ' is-disabled';
          }
        },
      },
      ElPagination: {
        props: {
          background: {
            type: Boolean,
            default: true,
          },
          layout: {
            type: String,
            default: 'prev, pager, next',
          },
        },
      },
      ElTag: {
        props: {
          size: {
            type: String,
            default: 'small',
          },
        },
      },
    };

    Object.keys(overrides).forEach((componentName) => {
      Vue.component(componentName, components[componentName].extend(overrides[componentName]));
    });
  },
};
