import { get as getConfig } from 'hcms-transforms/config';
import { getAll as getSkills } from 'hcms-transforms/skill';
import { getAll as getFundingSources } from 'hcms-transforms/funding_source';
import { getAll as getDropDowns } from 'hcms-transforms/drop_down';
import { getAll as getSharedDropDowns } from 'hcms-transforms/shared_drop_down';
import { sortArrayBy } from 'global-utils/methods';
import uniqBy from 'lodash.uniqby';

import { VM_CONFIGS } from 'hcms-constants/CONFIG_TYPES';
import { CLEAR_CONFIG, SET_CONFIG } from './mutationTypes';

export default {
  async getConfig({ commit, state }, configNames) {
    const missingConfigNames = configNames.filter((name) => !Object.prototype.hasOwnProperty.call(state, name));
    if (missingConfigNames.length === 0) {
      return state;
    }
    const nonVmConfigNames = missingConfigNames.filter((name) => !VM_CONFIGS.includes(name));
    const vmConfigNames = missingConfigNames.filter((name) => VM_CONFIGS.includes(name));

    const configs = await getConfig(nonVmConfigNames);
    const vmConfigs = await getConfig(vmConfigNames, true);

    Object.entries(configs).forEach(([name, config]) => {
      commit(SET_CONFIG, { name, config });
    });
    Object.entries(vmConfigs).forEach(([name, config]) => {
      commit(SET_CONFIG, { name, config });
    });

    return state;
  },
  async getSkills({ commit, state }, force) {
    if (state.skills.length && !force) {
      return;
    }

    const skills = await getSkills();
    commit(SET_CONFIG, {
      name: 'skills',
      config: skills,
    });
  },
  async getFundingSources({ commit, state }, force) {
    if (state.fundingSources.length && !force) {
      return;
    }

    const fundingSources = await getFundingSources();
    commit(SET_CONFIG, {
      name: 'fundingSources',
      config: fundingSources,
    });
  },
  async getDropDown({ commit, state }, { types, force, shared }) {
    const typesToGet = force ? types : types.filter((type) => !state.dropDowns.map((op) => op.type).includes(type));
    if (typesToGet.length === 0) {
      return;
    }

    const getter = shared ? getSharedDropDowns : getDropDowns;
    const dropDowns = await getter({
      drop_down_type: typesToGet.toString(),
      is_obsolete: false,
    });
    commit(SET_CONFIG, {
      name: 'dropDowns',
      config: sortArrayBy(uniqBy([...state.dropDowns, ...dropDowns], 'id'), 'label'),
    });
  },
  clearConfig({ commit }, ...configNames) {
    configNames.forEach((name) => commit(CLEAR_CONFIG, name));
  },
};
