const STAFF_PERSONAL_INFO = 'Staff';
const STAFF_LEAVE = 'StaffLeave';
const STAFF_EMPLOYMENT = 'StaffEmploymentRecord';
const STAFF_SUSPEND = 'StaffSuspendedRecord';
const CW_LEAVE = 'Leave';
const CW_AVAILABILITY = 'CwAvailability';
const CW_EMPLOYMENT = 'EmploymentRecord';
const CW_TRAINING = 'Training';
const CW_PERSONAL_INFO = 'CareWorker';
const CW_SUSPEND = 'SuspendedRecord';
const CW_TERMINATE = 'TerminationRequest';
const CW_PREFERENCE = 'HCMSCwPreference';
const CW_CERTIFICATE = 'Certificate';
const BOOKING = 'ScheduledBooking';
const SU_ADDRESS = 'Address';
const SU_SUSPEND = 'SuSuspend';
const SU_CARE_PLAN = 'CarePlan';
const BOOKING_CHANGE_REQUEST = 'BookingChangeRequest';
const REMINDER = 'Reminder';
const MEDICATION = 'Medication';
const MEDICAL_CONDITION = 'MedicalHistory';
const DATA_LOCK = 'DataLock';
const LEAVE_SUMMARY = 'LeaveSummary';
const STAFF_LEAVE_SUMMARY = 'StaffLeaveSummary';
const FORM_REQUEST = 'FormSubmissionRequest';
const VACCINATION_DETAILS = 'VaccinationDetails';
const STAFF_VACCINATION_DETAILS = 'StaffVaccinationDetails';
const MEDICATION_ISSUE_LOG = 'MedicationIssueLog';

export {
  STAFF_PERSONAL_INFO,
  STAFF_LEAVE,
  STAFF_EMPLOYMENT,
  STAFF_SUSPEND,
  CW_LEAVE,
  CW_AVAILABILITY,
  CW_EMPLOYMENT,
  CW_TRAINING,
  CW_PERSONAL_INFO,
  CW_SUSPEND,
  CW_TERMINATE,
  CW_PREFERENCE,
  CW_CERTIFICATE,
  BOOKING,
  SU_ADDRESS,
  SU_SUSPEND,
  SU_CARE_PLAN,
  BOOKING_CHANGE_REQUEST,
  REMINDER,
  MEDICATION,
  MEDICAL_CONDITION,
  DATA_LOCK,
  LEAVE_SUMMARY,
  STAFF_LEAVE_SUMMARY,
  FORM_REQUEST,
  VACCINATION_DETAILS,
  STAFF_VACCINATION_DETAILS,
  MEDICATION_ISSUE_LOG,
};

export default {
  STAFF_PERSONAL_INFO,
  STAFF_LEAVE,
  STAFF_EMPLOYMENT,
  STAFF_SUSPEND,
  CW_LEAVE,
  CW_AVAILABILITY,
  CW_EMPLOYMENT,
  CW_TRAINING,
  CW_PERSONAL_INFO,
  CW_SUSPEND,
  CW_TERMINATE,
  CW_PREFERENCE,
  CW_CERTIFICATE,
  BOOKING,
  SU_ADDRESS,
  SU_SUSPEND,
  SU_CARE_PLAN,
  BOOKING_CHANGE_REQUEST,
  REMINDER,
  MEDICATION,
  MEDICAL_CONDITION,
  DATA_LOCK,
  LEAVE_SUMMARY,
  STAFF_LEAVE_SUMMARY,
  FORM_REQUEST,
  VACCINATION_DETAILS,
  STAFF_VACCINATION_DETAILS,
  MEDICATION_ISSUE_LOG,
};
