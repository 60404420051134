import axios from 'axios';
import { emptyToNull, nullToEmpty } from 'global-utils';

import serviceUserTransforms from './serviceuser';
import profileTransforms from '../user/profile';

function transformResponse(user) {
  return nullToEmpty({
    email: user.email,
    isEmailVerified: user.is_email_verified,
    serviceUser: user.serviceuser
      ? serviceUserTransforms.transformResponse(user.serviceuser)
      : {},
    profile: user.profile
      ? profileTransforms.transformResponse(user.profile)
      : { profileImageId: [] },
    userName: user.user_name,
    suStatus: user.serviceuser ? user.serviceuser.su_status_mc_id : null,
  });
}

function transformRequest(user) {
  const requestUser = {};
  requestUser.email = user.email;
  if (user.serviceUser) {
    requestUser.serviceuser = serviceUserTransforms.transformRequest(
      user.serviceUser,
    );
  }
  if (user.profile) {
    requestUser.profile = profileTransforms.transformRequest(user.profile);
  }

  return emptyToNull({
    user: requestUser,
  });
}

async function getAll(params) {
  const url = 'api/su';
  const response = await axios.get(url, { params });
  const results = response.data.results.map(transformResponse);
  results.total = response.data.count;
  return results;
}

async function get(userName) {
  const url = `api/su/${userName}`;
  const response = await axios.get(url);

  return transformResponse(response.data.user);
}

async function put(userName, user) {
  const url = `api/su/${userName}`;
  const request = transformRequest(user);
  await axios.put(url, request);
}

async function post(user) {
  const url = 'api/su';
  const request = transformRequest(user);
  const response = await axios.post(url, request);
  return response.data.user_name;
}

export { getAll, get, post, put, transformResponse, transformRequest };
