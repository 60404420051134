import Vue from 'vue';
import Vuex from 'vuex';
// modules
import attachment from './attachment';
import config from './config';
import constants from './constants';
import engine from './engine';
import userList from './userList';

Vue.use(Vuex);

const debug = process.env.VUE_APP_DEBUG === 'true';

export default new Vuex.Store({
  modules: {
    attachment,
    config,
    constants,
    engine,
    userList,
  },
  strict: !debug,
});
