<template>
  <div class="lock__overlay">
    <div class="text-center">
      <i class="lock__icon ti ti-lock form-group"></i>
      <div v-html="reason"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardOverlay',
  props: {
    reason: {
      type: String,
      default: '',
    },
  },
};
</script>
