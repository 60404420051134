import axios from 'axios';
import { nullToEmpty } from 'global-utils';

function transformResponse(code) {
  return nullToEmpty({
    id: code.drop_down_id,
    type: code.drop_down_type,
    label: code.drop_down_label,
    isObsolete: code.is_obsolete,
  });
}

async function getAll(params) {
  const url = 'api/drop_down';
  const response = await axios.get(url, { params });
  return response.data.map(transformResponse);
}

export { getAll };
