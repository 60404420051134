import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VeeElement from 'vee-element';
import moment from 'moment';

import store from 'store';
import { formatDate, formatDateTimeZ } from 'global-utils';
import { PHONE_UK, POSTAL_CODE_UK, NI_NUMBER, NHS_NUMBER } from 'hcms-constants/REGEX';

VeeValidate.Validator.localize('en', {
  messages: {
    required() {
      return 'Required';
    },
    alpha_dash() {
      return 'Should have only alphabets, numbers, underscores and dashes';
    },
    email() {
      return 'Must be a valid email';
    },
    max(fieldName, value) {
      return `Should be ${value} characters or less`;
    },
    regex() {
      return 'Invalid Format';
    },
    is(fieldName, [value, label]) {
      return `Must be "${label || value}"`;
    },
    is_not(fieldName, [value, label]) {
      return `Must not be "${label || value}"`;
    },
  },
});

VeeValidate.Validator.extend('postcode', {
  getMessage: () => 'Must be a valid UK postcode',
  validate: (value) =>
    String(value)
      .replace(/[- ()]/g, '')
      .search(POSTAL_CODE_UK) !== -1,
});

VeeValidate.Validator.extend('phone', {
  getMessage: () => 'Must be a valid UK phone number',
  validate: (value) =>
    String(value)
      .replace(/[- ()]/g, '')
      .search(PHONE_UK) !== -1,
});

VeeValidate.Validator.extend('ni_number', {
  getMessage: () => 'Must be a valid UK NI number',
  validate: (value) =>
    String(value)
      .replace(/[- ()]/g, '')
      .search(NI_NUMBER) !== -1,
});

VeeValidate.Validator.extend('nhs_number', {
  getMessage: () => 'Must be a valid NHS number',
  validate: (value) =>
    String(value)
      .replace(/[- ()]/g, '')
      .search(NHS_NUMBER) !== -1,
});

VeeValidate.Validator.extend('isAfter', {
  getMessage: (fieldName, [minDate, showTime]) =>
    `Must be after ${showTime ? formatDateTimeZ(minDate) : formatDate(minDate)}`,
  validate: (value, [minDate]) => moment(value).isAfter(minDate),
});

VeeValidate.Validator.extend('isBefore', {
  getMessage: (fieldName, [maxDate, showTime]) =>
    `Must be before ${showTime ? formatDateTimeZ(maxDate) : formatDate(maxDate)}`,
  validate: (value, [maxDate]) => moment(value).isBefore(maxDate),
});

VeeValidate.Validator.extend('isSameOrAfter', {
  getMessage: (fieldName, [minDate, showTime]) =>
    `Must be after ${showTime ? formatDateTimeZ(minDate) : formatDate(minDate)}`,
  validate: (value, [minDate]) => moment(value).isSameOrAfter(minDate),
});

VeeValidate.Validator.extend('isSameOrBefore', {
  getMessage: (fieldName, [maxDate, showTime]) =>
    `Must be before ${showTime ? formatDateTimeZ(maxDate) : formatDate(maxDate)}`,
  validate: (value, [maxDate]) => moment(value).isSameOrBefore(maxDate),
});

VeeValidate.Validator.extend('gt', {
  getMessage: (fieldName, min) => `Must be more than ${min}`,
  validate: (value, [min]) => value > min,
});

VeeValidate.Validator.extend('lt', {
  getMessage: (fieldName, max) => `Must be less than ${max}`,
  validate: (value, [max]) => value < max,
});

VeeValidate.Validator.extend('gte', {
  getMessage: (fieldName, min) => `Must be ${min} or more`,
  validate: (value, [min]) => value >= min,
});

VeeValidate.Validator.extend('lte', {
  getMessage: (fieldName, max) => `Must be ${max} or less`,
  validate: (value, [max]) => value <= max,
});

VeeValidate.Validator.extend('isMultiple', {
  getMessage: (fieldName, multiplier) => `Must be a multiple of ${multiplier}`,
  validate: (value, [multiplier]) => value % multiplier === 0,
});

VeeValidate.Validator.extend('insideCriticalPeriod', {
  getMessage: () => 'Must be inside critical period',
  validate: (value) => value <= store.state.constants.NEXT_SESSION_END,
});

VeeValidate.Validator.extend('outsideCriticalPeriod', {
  getMessage: () => 'Must be outside critical period',
  validate: (value) => value > store.state.constants.NEXT_SESSION_END,
});

VeeValidate.Validator.extend('isChecked', {
  getMessage: () => 'Must be selected',
  validate: (value) => value === true,
});

Vue.use(VeeValidate, {
  errorBagName: 'errors',
  fieldsBagName: 'veeFields',
});

const validator = new VeeValidate.Validator({}, {});

Vue.use(VeeElement, validator);
