import { engineRunStatusUtils } from 'hcms-mc-utils';
import { formatDate, formatDateRange } from 'global-utils';

const ENGINE_RUN_RUNNING = engineRunStatusUtils.DICT.RUNNING;
const ENGINE_RUN_APPROVED = engineRunStatusUtils.DICT.APPROVED;

export default {
  isRunning(state) {
    return (
      state.lastReport && state.lastReport.engineRunStatus === ENGINE_RUN_RUNNING && !state.lastReport.isTerminated
    );
  },
  isCancelling(state) {
    return state.lastReport && state.lastReport.engineRunStatus === ENGINE_RUN_RUNNING && state.lastReport.isTerminated;
  },
  isNotifying(state) {
    return state.lastReport && state.lastReport.engineRunStatus === ENGINE_RUN_APPROVED && !state.lastReport.isNotified;
  },
  isNotified(state) {
    return state.lastReport && state.lastReport.isNotified;
  },
  isPending(state) {
    return (
      state.lastReport &&
      state.lastReport.engineRunStatus === engineRunStatusUtils.DICT.PENDING &&
      !state.lastReport.isTerminated
    );
  },
  formattedDuration(state) {
    if (!state.lastReport) {
      return '';
    }

    const isStandard = state.lastReport.allocationMode === 'Standard';

    const { startDate, endDate } = state.lastReport;
    if (isStandard) {
      return formatDateRange(startDate, endDate);
    }

    if (startDate.format('HH:mm') === '00:00' && endDate.format('HH:mm') === '23:59') {
      return formatDateRange(startDate, endDate);
    }

    if (formatDate(startDate) === formatDate(endDate)) {
      return `${startDate.format('D MMM, H:mm')} - ${endDate.format('H:mm')}`;
    }

    return `${startDate.format('D MMM, H:mm')} - ${endDate.format('D MMM, H:mm')}`;
  },
};
