import { currentUser } from 'auth-utils';
import { AUTH as AUTH_PATH } from 'hcms-constants/APP_PATHS';

function checkAuthentication() {
  if (!currentUser.isAuthenticated) {
    window.location.replace(`${AUTH_PATH}?redirect_url=${window.location.href}`);
    return;
  }

  if (!currentUser.roleConst || !currentUser.roleConst.meta.isStaff) {
    window.location.replace(`${AUTH_PATH}`);
  }
}

checkAuthentication();
