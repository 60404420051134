import { sortArrayBy } from 'global-utils';
import { isTruthy } from 'hcms-transforms/config';
import { LICENSE_PARAMS, SYSTEM_MODE, VM_SENSING_MODE } from 'hcms-constants/CONFIG_TYPES';
import { currentUser } from 'auth-utils';
import { roleUtils } from 'hcms-const-utils';
import ROUTE_PERMISSIONS from './ROUTE_PERMISSIONS';

const PAYSLIP_INVOICE_ROUTES = ['Manage Payslips', 'Care Worker Payslips', 'Manage Invoices', 'Service User Invoices'];

if (currentUser.role === roleUtils.DICT.FIN) {
  PAYSLIP_INVOICE_ROUTES.push('Overview');
}

const FORM_ROUTES = ['Form Templates', 'All Form Submissions', 'Form Submission Requests', 'Form Submissions'];

const LIVE_ROUTES = ['AI Scheduler'];

export default {
  skillOptions(state) {
    return sortArrayBy(state.skills, 'skillName').map((skill) => ({
      value: skill.skillTemplateId,
      label: skill.skillName,
      meta: skill,
    }));
  },
  fundingSourceOptions(state) {
    return state.fundingSources.map((op) => ({
      value: op.fundingSourceId,
      label: op.name,
    }));
  },
  toFundingSource(state) {
    return (id) => state.fundingSources.find((op) => op.fundingSourceId === id);
  },
  toFundingSourceName(state, getters) {
    return (id) => id && getters.toFundingSource(id).name;
  },
  hasFormAccess(state) {
    return state[LICENSE_PARAMS] ? isTruthy(state[LICENSE_PARAMS].FORM_ACCESS) : false;
  },
  hasPayslipInvoiceAccess(state) {
    return state[LICENSE_PARAMS] ? isTruthy(state[LICENSE_PARAMS].PAYSLIP_INVOICE_ACCESS) : false;
  },
  hasVmAccess(state) {
    // return false;
    return state[LICENSE_PARAMS] ? isTruthy(state[LICENSE_PARAMS].VM_ACCESS) : false;
  },
  isVmSensingAdvanced(state) {
    return state[VM_SENSING_MODE] ? isTruthy(state[VM_SENSING_MODE].DEFAULT) : false;
  },
  hasRouteAccess(state, getters) {
    return (name) => {
      if (!ROUTE_PERMISSIONS[name]) {
        return false;
      }
      if (PAYSLIP_INVOICE_ROUTES.includes(name)) {
        return getters.hasPayslipInvoiceAccess;
      }
      if (FORM_ROUTES.includes(name)) {
        return getters.hasFormAccess;
      }
      if (LIVE_ROUTES.includes(name)) {
        return getters.isLiveMode;
      }
      return true;
    };
  },
  isOnboardingMode(state) {
    // * "0" is Onboarding, "1" is Live
    return state[SYSTEM_MODE] ? !isTruthy(state[SYSTEM_MODE].DEFAULT) : false;
  },
  isLiveMode(state, getters) {
    // * "0" is Onboarding, "1" is Live
    return !getters.isOnboardingMode;
  },
};
