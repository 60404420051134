export default {
  transformResponse(serviceUser) {
    return {
      socialServiceRef: serviceUser.social_service_ref,
      sexualOrientation: serviceUser.sexual_orientation_mc_id,
      suStatus: serviceUser.su_status_mc_id,
      hasPets: serviceUser.has_no_pets,
    };
  },
  transformRequest(serviceUser) {
    return {
      social_service_ref: serviceUser.socialServiceRef,
      has_no_pets: serviceUser.hasPets,
      sexual_orientation_mc_id: serviceUser.sexualOrientation,
    };
  },
};
