import { emptyToNull, nullToEmpty } from 'global-utils';

import axios from 'axios';
import moment from 'moment';

function transformResponse(fundingSource) {
  return nullToEmpty({
    fundingSourceId: fundingSource.funding_source_id,
    name: fundingSource.name,
    fundingType: fundingSource.funding_type_mc_id,

    street1: fundingSource.street_1,
    street2: fundingSource.street_2,
    postCode: fundingSource.post_code,
    telephone1: fundingSource.telephone_1,
    email: fundingSource.email,

    lastModifiedBy: fundingSource.last_modified_by,
    lastModifiedDate: moment(fundingSource.last_modified_date),
  });
}

function transformRequest(fundingSource) {
  return emptyToNull({
    funding_source: {
      funding_source_id: fundingSource.fundingSourceId,
      name: fundingSource.name,
      funding_type_mc_id: fundingSource.fundingType,

      street_1: fundingSource.street1,
      street_2: fundingSource.street2,
      post_code: fundingSource.postCode,
      telephone_1: fundingSource.telephone1,
      email: fundingSource.email,
    },
  });
}

async function getAll(params) {
  const url = 'api/funding_source';
  const response = await axios.get(url, { params });
  const results = response.data.results.map(transformResponse);
  results.total = response.data.count;
  return results;
}

async function get(fundingSourceId) {
  const url = `api/funding_source/${fundingSourceId}`;
  const response = await axios.get(url);

  return transformResponse(response.data.funding_source);
}

async function post(fundingSource) {
  const url = 'api/funding_source';
  const request = transformRequest(fundingSource);
  const response = await axios.post(url, request);
  return response.data.funding_source_id;
}

async function put(fundingSourceId, fundingSource) {
  const url = `api/funding_source/${fundingSourceId}`;
  const request = transformRequest(fundingSource);
  await axios.put(url, request);
}

export { getAll, get, post, put, transformResponse, transformRequest };
