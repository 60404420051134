import { currentUser } from 'auth-utils';
import { roleUtils } from 'hcms-const-utils';
import { engineRunStatusUtils } from 'hcms-mc-utils';
import { getAll as getReports } from 'hcms-transforms/engine';
import moment from 'moment';
import { MINUTE } from 'tz-utils';
import { SET_CONSTANT } from './mutationTypes';

const ENGINE_RUN_APPROVED = engineRunStatusUtils.DICT.APPROVED;

export default {
  async setConstant({ commit }, type, value) {
    commit(SET_CONSTANT, { type, value });
  },
  async getNotifiedPeriod({ commit }) {
    const params = {
      limit: 1,
      engine_run_status: ENGINE_RUN_APPROVED,
      sort: '-finish_date',
    };
    const engineRuns = await getReports(params);
    const lastEngineRun = engineRuns[0];
    if (lastEngineRun) {
      commit(SET_CONSTANT, {
        type: 'NOTIFIED_PERIOD_END',
        value: lastEngineRun.endDate,
      });
    }
  },
  async updateTime({ commit }) {
    const NOW = moment();
    const START_OF_HOUR = moment(NOW).startOf('hour');
    const MS_SINCE_HOUR = moment(NOW).diff(START_OF_HOUR, 'ms');
    const START_OF_FIVE_MINUTES = moment(NOW).subtract(MS_SINCE_HOUR % (5 * MINUTE), 'ms');
    const IN15 = moment(START_OF_FIVE_MINUTES).add(15, 'minutes');
    const TODAY = moment(NOW).startOf('day');
    const END_OF_TODAY = moment(NOW).endOf('day');
    const CURRENT_SESSION_START = moment(NOW).startOf('week');
    const CURRENT_SESSION_END = moment(NOW).endOf('week');
    const START_OF_CURRENT_HALF = moment(START_OF_HOUR).subtract(NOW.hours() % 12, 'hours');
    const END_OF_CURRENT_HALF = moment(START_OF_CURRENT_HALF).add(11, 'hours').endOf('hour');
    const NEXT_SESSION_START = moment(CURRENT_SESSION_END).add(1, 'ms');
    const NEXT_SESSION_END = moment(CURRENT_SESSION_END).add(1, 'week');
    const BOOKINGS_GENERATED_UNTIL = moment(NEXT_SESSION_END).add(1, 'week');
    const NON_CRITICAL_PERIOD_START = moment(NEXT_SESSION_END).add(1, 'ms');
    const ALERT_PERIOD = moment(NEXT_SESSION_END).add(currentUser.role === roleUtils.DICT.HR ? 1 : 0, 'week');

    commit(SET_CONSTANT, { type: 'NOW', value: NOW });
    commit(SET_CONSTANT, { type: 'START_OF_HOUR', value: START_OF_HOUR });
    commit(SET_CONSTANT, {
      type: 'START_OF_FIVE_MINUTES',
      value: START_OF_FIVE_MINUTES,
    });
    commit(SET_CONSTANT, { type: 'IN15', value: IN15 });
    commit(SET_CONSTANT, { type: 'TODAY', value: TODAY });
    commit(SET_CONSTANT, { type: 'END_OF_TODAY', value: END_OF_TODAY });
    commit(SET_CONSTANT, {
      type: 'START_OF_CURRENT_HALF',
      value: START_OF_CURRENT_HALF,
    });
    commit(SET_CONSTANT, {
      type: 'END_OF_CURRENT_HALF',
      value: END_OF_CURRENT_HALF,
    });
    commit(SET_CONSTANT, {
      type: 'CURRENT_SESSION_START',
      value: CURRENT_SESSION_START,
    });
    commit(SET_CONSTANT, {
      type: 'CURRENT_SESSION_END',
      value: CURRENT_SESSION_END,
    });
    commit(SET_CONSTANT, {
      type: 'NEXT_SESSION_START',
      value: NEXT_SESSION_START,
    });
    commit(SET_CONSTANT, { type: 'NEXT_SESSION_END', value: NEXT_SESSION_END });
    commit(SET_CONSTANT, {
      type: 'BOOKINGS_GENERATED_UNTIL',
      value: BOOKINGS_GENERATED_UNTIL,
    });
    commit(SET_CONSTANT, {
      type: 'NON_CRITICAL_PERIOD_START',
      value: NON_CRITICAL_PERIOD_START,
    });
    commit(SET_CONSTANT, { type: 'ALERT_PERIOD', value: ALERT_PERIOD });
  },
};
