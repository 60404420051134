import { currentUser } from 'auth-utils';
import { sortArrayBy } from 'global-utils';
import { roleUtils } from 'hcms-const-utils';

const toShortRole = (role) => roleUtils.toMeta(role)?.shortName || role;

const STAFF_ROLES = roleUtils.OPTIONS.filter((opt) => opt.meta.isStaff).map((op) => op.value);
const INTERNAL_ROLES = roleUtils.OPTIONS.filter((op) => op.meta.isInternal).map((op) => op.value);

const ROLE_SORTING_ORDER = [
  roleUtils.DICT.CTR,
  roleUtils.DICT.HR,
  roleUtils.DICT.SUP,
  roleUtils.DICT.MGT,
  roleUtils.DICT.ADMIN,
  roleUtils.DICT.SYS,
  roleUtils.DICT.FIN,
  roleUtils.DICT.SU,
  roleUtils.DICT.CW,
];

export default {
  userList(state) {
    const userList = Object.keys(state.users)
      .map((userName) => state.users[userName])
      .filter((user) => !INTERNAL_ROLES.includes(user.role));
    return sortArrayBy(
      userList,
      (user) => user.userName !== currentUser.userName,
      (user) => ROLE_SORTING_ORDER.indexOf(user.role),
      'firstName',
      'lastName',
    );
  },
  careWorkers(state, getters) {
    return getters.userList.filter((user) => user.role === roleUtils.DICT.CW);
  },
  serviceUsers(state, getters) {
    return getters.userList.filter((user) => user.role === roleUtils.DICT.SU);
  },
  staff(state, getters) {
    return getters.userList.filter((user) => STAFF_ROLES.includes(user.role));
  },
  getUser(state) {
    return (userName) => state.users[userName];
  },
  getName(state) {
    return (
      userName,
      options = {
        role: false,
        title: true,
      },
    ) => {
      const user = state.users[userName];

      if (!user) {
        return 'User not found';
      }

      return [
        options.role ? `${toShortRole(user.role)}:` : '',
        options.title ? user.title : '',
        user.firstName,
        user.lastName,
      ]
        .filter((val) => val)
        .join(' ');
    };
  },
};
