<template>
  <CustomTable v-if="data.length" ref="table" :data="data" :height="height" v-bind="$attrs" v-on="$listeners">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </CustomTable>
  <div
    class="my-0 py-2 d-flex align-center justify-center text-muted h5"
    v-else
    :style="{
      height: height ? `${height}px` : '',
    }">
    {{ emptyText }}
  </div>
</template>
<script>
import { Table } from 'element-ui';

export default {
  name: 'NewTable',

  components: {
    CustomTable: Table,
  },

  props: {
    emptyText: {
      type: String,
      default: 'No Records',
    },
    data: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
    },
  },
  methods: {
    clearSelection(...args) {
      if (this.$refs.table) {
        this.$refs.table.clearSelection(...args);
      }
    },
    toggleRowSelection(...args) {
      if (this.$refs.table) {
        this.$refs.table.toggleRowSelection(...args);
      }
    },
    toggleAllSelection(...args) {
      if (this.$refs.table) {
        this.$refs.table.toggleAllSelection(...args);
      }
    },
    toggleRowExpansion(...args) {
      if (this.$refs.table) {
        this.$refs.table.toggleRowExpansion(...args);
      }
    },
    setCurrentRow(...args) {
      if (this.$refs.table) {
        this.$refs.table.setCurrentRow(...args);
      }
    },
    clearSort(...args) {
      if (this.$refs.table) {
        this.$refs.table.clearSort(...args);
      }
    },
    clearFilter(...args) {
      if (this.$refs.table) {
        this.$refs.table.clearFilter(...args);
      }
    },
    doLayout(...args) {
      if (this.$refs.table) {
        this.$refs.table.doLayout(...args);
      }
    },
    sort(...args) {
      if (this.$refs.table) {
        this.$refs.table.sort(...args);
      }
    },
  },
};
</script>
