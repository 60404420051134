import axios from 'axios';
import moment from 'moment';

import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';
import { get as getConfig } from '../config';

const IN_PROGRESS = 206;

let configs = {};

// ! Assuming SU_CENTRIC is always going to be the highest priority
const PRIVATE_CONFIG_NAME = 'SERVICE_CENTRIC_PRIVATE_BOOKING_PRIORITY';
const CRITICAL_CONFIG_NAME = 'SERVICE_CENTRIC_CRITICAL_BOOKING_PRIORITY';

const fetchConfigs = async () => {
  if (!Object.prototype.hasOwnProperty.call(configs, PRIVATE_CONFIG_NAME)) {
    configs = await getConfig([PRIVATE_CONFIG_NAME, CRITICAL_CONFIG_NAME]);
  }
};

function transformResponse(report, isRunComplete = true) {
  if (isRunComplete === false) {
    return {
      startDate: moment(report.start_date),
      endDate: moment(report.finish_date),
      bookingCount: report.booking_count,
      allocatedCount: report.allocated_count,
    };
  }
  const allocationMode = report.allocation_preset_mc_id
    ? 'Standard'
    : 'Advanced';
  const baseReport = {
    reportId: report.engine_run_id,
    createdDate: moment(report.created_date),
    createdBy: report.created_by,
    startDate: moment(report.start_date),
    endDate: moment(report.finish_date),
    isNotified: Boolean(report.is_notified),
    reportUrl: `${SERVER_ADDRESS}/api/export_pdf_engine_detail?id=${report.engine_run_id}`,
    runTime: Number(report.runtime),
    allocationRunTime: Number(report.allocation_runtime),
    engineRunStatus: report.engine_run_status_mc_id,
    allocationMode,
    allocationChangePercentage: report.changed_percentage
      ? Number(report.changed_percentage.toFixed(2))
      : 0,
    reallocateExistingBookings: Boolean(report.reallocate_existing_bookings),
    rawReport: report.raw_report,
    notifiedReport: report.notified_report,
    scheduleReport: report.schedule_report,
    isStale: report.is_stale,
    isTerminated: report.is_terminated,
    allocatedCount: report.allocated_count,
    unallocatedCount: report.unallocated_count,
  };

  if (allocationMode === 'Advanced') {
    return {
      ...baseReport,
      suWeight: report.su_weight,
      cwWeight: report.cw_weight,
      mgtWeight: report.mgt_weight,
      allowHardViolation: Boolean(report.allow_hard_violation),
      maxTravelTime: report.max_travel_time,
      travelTimeEnforcement: report.travel_time_enforcement,
      allocationChangeLimit: report.change_percentage,
      priorityRatio:
        report.private_booking_priority /
        configs[PRIVATE_CONFIG_NAME].getValue(),
    };
  }

  return {
    ...baseReport,
    allocationPreset: report.allocation_preset_mc_id,
  };
}

function transformRequest(report) {
  const baseReport = {
    start_date: moment(report.startDate).toISOString(),
    finish_date: moment(report.endDate).toISOString(),
    reallocate_existing_bookings: Boolean(report.reallocateExistingBookings),
  };
  if (report.allocationMode === 'Advanced') {
    return {
      ...baseReport,
      su_weight: report.suWeight,
      cw_weight: report.cwWeight,
      mgt_weight: report.mgtWeight,
      allow_hard_violation: Boolean(report.allowHardViolation),
      max_travel_time: report.maxTravelTime,
      travel_time_enforcement: report.travelTimeEnforcement,
      change_percentage: report.allocationChangeLimit,
      private_booking_priority:
        report.priorityRatio * configs[PRIVATE_CONFIG_NAME].getValue(),
      critical_booking_priority:
        report.priorityRatio * configs[CRITICAL_CONFIG_NAME].getValue(),
    };
  }

  return {
    ...baseReport,
    allocation_preset_mc_id: report.allocationPreset,
  };
}

async function getAll(params) {
  const url = 'api/engine';
  await fetchConfigs();
  const response = await axios.get(url, { params });

  const results = response.data.results.map(transformResponse);
  results.total = response.data.count;
  return results;
}

async function get(reportId) {
  const url = `api/engine/${reportId}`;
  await fetchConfigs();
  const response = await axios.get(url);

  const isRunComplete = response.status !== IN_PROGRESS;

  return transformResponse(response.data, isRunComplete);
}

async function post(report) {
  const url = 'api/engine';
  await fetchConfigs();
  const request = transformRequest(report);
  const response = await axios.post(url, request);
  return response.data.engine_run_id;
}

export { getAll, get, post, transformRequest, transformResponse };
