export default [
  {
    name: 'Overview',
    icon: 'ti ti-dashboard',
    path: { name: 'Overview' },
  },
  {
    name: 'Log Requests',
    icon: 'ti ti-check-box',
    path: { name: 'Log Requests' },
  },
  {
    name: 'Notifications',
    icon: 'ti ti-info-alt',
    path: { name: 'Notifications' },
  },
  {
    name: 'Actions',
    icon: 'ti ti-check-box',
    path: { name: 'Actions' },
  },
  {
    name: 'Staff',
    icon: 'ti ti-user',
    children: [
      { name: 'List', path: { name: 'Staff List' } },
      { name: 'Info Details', path: { name: 'Staff Details' } },
    ],
  },
  {
    name: 'Care Worker',
    icon: 'ti ti-id-badge',
    children: [
      { name: 'List', path: { name: 'Care Worker List' } },
      { name: 'Info Details', path: { name: 'Care Worker Details' } },
      { name: 'Payslips', path: { name: 'Manage Payslips' } },
    ],
  },
  {
    name: 'Service User',
    icon: 'ti ti-support',
    children: [
      { name: 'List', path: { name: 'Service User List' } },
      { name: 'Info Details', path: { name: 'Service User Details' } },
      { name: 'Pricing', path: { name: 'Service User Pricing Plans' } },
      { name: 'Invoicing', path: { name: 'Manage Invoices' } },
    ],
  },
  {
    name: 'Bookings',
    icon: 'ti ti-map-alt',
    children: [
      { name: 'Block View', path: { name: 'Bookings Block View' } },
      { name: 'Compare View', path: { name: 'Bookings Compare View' } },
      { name: 'List View', path: { name: 'Bookings List View' } },
    ],
  },
  {
    name: 'AI Scheduler',
    icon: 'ti ti-panel',
    path: { name: 'AI Scheduler' },
  },
  {
    name: 'Compliance Manager',
    icon: 'ti ti-pencil-alt',
    children: [
      { name: 'Requests', path: { name: 'Form Submission Requests' } },
      { name: 'Submissions', path: { name: 'All Form Submissions' } },
      { name: 'Templates', path: { name: 'Form Templates' } },
    ],
  },
];
