import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_DSN !== 'false') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_MODE,
    release: process.env.RELEASE,
    Vue,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },
  });
}
