import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const defaultState = {
  skills: [],
  fundingSources: [],
  dropDowns: [],
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
