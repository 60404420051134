<template>
  <div class="full-screen d-flex align-center">
    <div class="container">
      <div class="row">
        <div class="text-center pa-4">
          <h2 class="mt-0 text-danger">Offline</h2>
          <h2>
            It seems there is an issue with your internet connection
            <br />
            <br />
            Please check your connection before going back
          </h2>
          <button class="mt-4 btn btn-simple btn-fill btn-lg" @click="back">
            <i class="ti ti-arrow-left mr-2"></i>
            Go Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    back() {
      window.history.back();
    },
  },
};
</script>
