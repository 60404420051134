import 'setup/sentry';
import 'setup/checkAuthentication';
import 'setup/locale';
import 'setup/pollyfills';
import 'setup/element';
import 'setup/validator';
import 'setup/axios';
import 'setup/sync';
import 'assets/sass/lib.scss';
import 'assets/sass/hcms.scss';

import { Plugin as DataHooks, add as addHook } from 'mixins/DataHook';
import { LICENSE_PARAMS, PROVIDER_CONFIG, SYSTEM_MODE } from 'hcms-constants/CONFIG_TYPES';

import App from '@/App.vue';
import AuditBtn from 'components/AuditBtn.vue';
// Custom Components
import Contact from 'components/Contact.vue';
import CustomSelect from 'components/Select.vue';
import DataLock from 'mixins/DataLock';
import DatePicker from 'hcms-components/DatePicker.vue';
import DateRange from 'components/DateRange.vue';
// Custom Plugins
import { Plugin as GlobalUtils } from 'global-utils';
import Loading from 'directives/Loading';
import Lock from 'directives/Lock';
import Notify from 'utils/notify';
import ShowModal from 'vue-modal-promise';
import Table from 'components/Table.vue';
import TimeSelect from 'components/TimeSelect.vue';
import UploadFile from 'components/UploadFile.vue';
import Vue from 'vue';
import VueConstants from 'vue-constants';
import debounce from 'lodash.debounce';
import { hasAccess as hasModelAccess } from 'utils/accessUtils';
import moment from 'moment';
import router from 'router';
import setDefaultTimezone from 'setup/timezone';
import store from 'store';

Vue.use(VueConstants);

Vue.use(GlobalUtils);
Vue.use(ShowModal);
Vue.use(Notify);
Vue.use(Lock);
Vue.use(Loading);
Vue.use(DataHooks);
Vue.use(DataLock);

Vue.component('AuditBtn', AuditBtn);
Vue.component('Contact', Contact);
Vue.component('DatePicker', DatePicker);
Vue.component('DateRange', DateRange);
Vue.component('ElTable', Table);
Vue.component('ElTimeSelect', TimeSelect);
Vue.component('OpSelect', CustomSelect);
Vue.component('UploadFile', UploadFile);

Vue.config.productionTip = false;

Vue.prototype.SHOW_VISIT_MONITORING = false;
Vue.prototype.hasModelAccess = hasModelAccess;

const promises = [
  setDefaultTimezone(),
  store.dispatch('getUsers'),
  store.dispatch('getNotifiedPeriod'),
  store.dispatch('getLastEngineRun'),
  store.dispatch('getBookingGenerationStatus'),
  store.dispatch('getFundingSources'),
  store.dispatch('getConfig', [LICENSE_PARAMS, SYSTEM_MODE, PROVIDER_CONFIG]),
];

Vue.prototype.publicPath = process.env.BASE_URL;

Promise.all(promises).then(() => {
  store.dispatch('updateTime');
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
    store,
  });
});

const { NOW, START_OF_FIVE_MINUTES } = store.state.constants;
const NEXT_FIVE_MINS = moment(START_OF_FIVE_MINUTES).add(5, 'minutes');
const FIVE_MINS = moment(NEXT_FIVE_MINS).diff(START_OF_FIVE_MINUTES);
const UNTIL_END_OF_FIVE_MIN = moment(NEXT_FIVE_MINS).diff(NOW);

addHook(
  'EngineRun',
  debounce(() => {
    store.dispatch('getLastEngineRun');
    store.dispatch('getNotifiedPeriod');
  }, 100),
);

addHook(
  'BookingGen',
  debounce(() => {
    store.dispatch('getBookingGenerationStatus');
  }, 100),
);

addHook('FundingSource', () => store.dispatch('getFundingSource'));

window.setTimeout(() => {
  store.dispatch('updateTime');
  window.setInterval(() => {
    store.dispatch('updateTime');
  }, FIVE_MINS);
}, UNTIL_END_OF_FIVE_MIN);
