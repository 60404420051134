const CW_PAYSLIP = 'CW_PAYSLIP';
const LICENSE_PARAMS = 'LICENSE_PARAMS';
const LONG_BOOKING_THRESHOLD = 'LONG_BOOKING_THRESHOLD';
const PART_TIME_CONTRACT_MAX = 'PARTTIME_CONTRACT_MAX';
const PROVIDER_CONFIG = 'PROVIDER_DETAILS';
const QUALITY_THRESHOLDS = 'QUALITY_THRESHOLDS';
const SYSTEM_MODE = 'SYSTEM_MODE';
const VM_SENSING_MODE = 'SENSING_MODE_IS_ADVANCED';

export const VM_CONFIGS = [VM_SENSING_MODE];

export default {
  CW_PAYSLIP,
  LICENSE_PARAMS,
  LONG_BOOKING_THRESHOLD,
  PART_TIME_CONTRACT_MAX,
  PROVIDER_CONFIG,
  QUALITY_THRESHOLDS,
  SYSTEM_MODE,
  VM_SENSING_MODE,
};

export {
  CW_PAYSLIP,
  LICENSE_PARAMS,
  LONG_BOOKING_THRESHOLD,
  PART_TIME_CONTRACT_MAX,
  PROVIDER_CONFIG,
  QUALITY_THRESHOLDS,
  SYSTEM_MODE,
  VM_SENSING_MODE,
};
