import { currentUser } from 'auth-utils';

import ACCESS_MAP from './ACCESS_MAP';

const getPermissionIndex = (permissionType) => {
  if (permissionType === 'read') {
    return 0;
  }
  if (permissionType === 'write') {
    return 1;
  }
  if (permissionType === 'update') {
    return 2;
  }
  throw new Error('Unrecognized permission type');
};

function hasAccess(modelName, permissionType = 'read') {
  const index = getPermissionIndex(permissionType);
  const modelPermissions = ACCESS_MAP[modelName];
  const permission = modelPermissions[index];

  if (typeof permission === 'boolean') {
    return permission;
  }

  if (Array.isArray(permission)) {
    return permission.includes(currentUser.role);
  }

  return false;
}

export { hasAccess };
