<template>
  <button
    class="btn btn-icon btn-simple ti ti-time"
    title="Show Previous Records"
    @click.prevent="openAudit()"></button>
</template>
<script>
const Audit = () => import(/* webpackChunkName:"Audit" */ 'views/Modals/Audit.vue');

export default {
  name: 'AuditBtn',
  methods: {
    openAudit() {
      this.$showModal({
        component: Audit,
        props: this.$attrs,
      });
    },
  },
};
</script>
