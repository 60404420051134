<template>
  <el-select
    :value="value"
    @input="updateModel"
    :remote="isRemote"
    :disabled="isDisabled"
    :placeholder="customPlaceHolder"
    :remoteMethod="isRemote ? getOptions : undefined"
    :loading="isLoading"
    v-bind="$attrs">
    <slot name="pre" />
    <el-option v-for="{ value, label } in resolvedOptions" :key="JSON.stringify(value)" :label="label" :value="value">
    </el-option>
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </el-select>
</template>
<script>
export default {
  name: 'CustomSelect',
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    options: {
      default: () => [],
      validator: (value) => typeof value === 'function' || Array.isArray(value),
    },
    loading: {
      type: Boolean,
    },
  },
  inject: {
    elForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      asyncOptions: [],
      asyncLoading: false,
    };
  },
  computed: {
    isDisabled() {
      return Boolean(this.disabled || this.elForm.disabled);
    },
    customPlaceHolder() {
      if (this.placeholder) {
        return this.placeholder;
      }

      return this.isDisabled && !this.value ? 'Not Specified' : 'Select';
    },
    isLoading() {
      return this.loading || this.asyncLoading;
    },
    isRemote() {
      return typeof this.options === 'function';
    },
    resolvedOptions() {
      if (!this.isRemote) {
        return this.options;
      }
      return this.asyncOptions;
    },
  },
  methods: {
    async getOptions(query) {
      this.asyncLoading = true;
      this.asyncOptions = await this.options(query);
      this.asyncLoading = false;
    },
    updateModel(val) {
      this.$emit('input', val);
    },
  },
};
</script>
