<template>
  <div class="hcms-overrides" @click="clickLog">
    <router-view v-if="$route.meta.disableLayout" />
    <dashboard-layout v-else />
  </div>
</template>

<script>
import moment from 'moment';
import { post as logActivity } from 'hcms-transforms/log_activity';
import debounce from 'lodash.debounce';
import DashboardLayout from 'layouts/DashboardLayout.vue';
import { MINUTE } from 'tz-utils';

const FIVE_MINUTES = 5 * MINUTE;

export default {
  name: 'app',
  components: {
    DashboardLayout,
  },
  data() {
    return {
      lastActivity: moment(),
    };
  },
  methods: {
    async refreshLogin() {
      try {
        await logActivity();
        this.lastActivity = moment();
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.clickLog = debounce(
      () => {
        this.refreshLogin();
      },
      FIVE_MINUTES,
      { leading: true, trailing: false },
    );
  },
};
</script>
