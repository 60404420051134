import { SET_LAST_REPORT, UPDATE_LAST_REPORT, CLEAR_LAST_REPORT, SET_BOOKING_GENERATION_STATUS } from './mutationTypes';

export default {
  [SET_LAST_REPORT]: (state, report) => {
    state.lastReport = report;
  },
  [UPDATE_LAST_REPORT]: (state, report) => {
    state.lastReport = {
      ...state.lastReport,
      ...report,
    };
  },
  [CLEAR_LAST_REPORT]: (state) => {
    state.lastReport = '';
  },
  [SET_BOOKING_GENERATION_STATUS]: (state, status) => {
    state.bookingGenerationStatus = status;
  },
};
