import { equalJSON } from 'global-utils';
import { CLEAR_CONFIG, SET_CONFIG } from './mutationTypes';

export default {
  [SET_CONFIG]: (state, { name, config }) => {
    if (!equalJSON(state[name], config)) {
      state[name] = config;
    }
  },
  [CLEAR_CONFIG]: (state, name) => {
    if (['skills', 'fundingSources', 'dropDowns'].includes(name)) {
      state[name] = [];
    } else {
      delete state[name];
    }
  },
};
