import moment from 'moment';
import axios from 'axios';

import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';

function transformResponse(attachment) {
  return {
    attachmentId: attachment.document_id,
    createdDate: attachment.created_date ? moment(attachment.created_date) : '',
    createdBy: attachment.created_by,
    note: attachment.note,
    name: attachment.name,
    isRelevant: attachment.is_relevant,
    url: `${SERVER_ADDRESS}/api/attachments/${attachment.document_id}/download`,
    attachmentType: attachment.doc_type_mc_id,
  };
}

function transformRequest(attachment) {
  const formData = new FormData();
  const { fileData } = attachment;
  formData.set('file', fileData, fileData.name);
  formData.set('note', attachment.note);
  if (attachment.attachmentType) {
    formData.set('doc_type_mc_id', attachment.attachmentType);
  }
  return formData;
}

async function getAll(userName, params) {
  const url = `api/user/${userName}/attachments`;
  const response = await axios.get(url, { params });
  const results = response.data.map(transformResponse);
  results.total = response.data.length;
  return results;
}

async function get(attachmentId) {
  const url = `api/attachments/${attachmentId}`;
  const response = await axios.get(url);
  return transformResponse(response.data);
}

async function post(userName, attachment) {
  if (typeof userName === 'object' && attachment === undefined) {
    return post('', userName);
  }
  const url = userName ? `api/user/${userName}/attachments` : 'api/attachments';
  const requestData = transformRequest(attachment);
  const response = await axios.post(url, requestData);
  return transformResponse(response.data);
}

async function remove(attachmentId) {
  const url = `api/attachments/${attachmentId}`;
  await axios.delete(url);
}

export { get, getAll, post, remove, transformResponse, transformRequest };
