<template>
  <router-link
    v-if="link.path"
    class="sidebar-item"
    :to="link.path"
    active-class="sidebar-item--active sidebar-item--caret">
    <i class="sidebar-item__icon" :class="link.icon"></i>
    <span class="sidebar-item__name">
      {{ link.name }}
    </span>
  </router-link>
  <div v-else>
    <a
      href="javascript:;"
      class="sidebar-item sidebar-item--parent"
      :class="{
        'sidebar-item--expanded': expanded,
        'sidebar-item--active': isChildActive,
      }"
      @click="toggleExpand">
      <i class="sidebar-item__icon" :class="link.icon"></i>
      <span class="sidebar-item__name">
        {{ link.name }}
      </span>
    </a>
    <collapse-transition>
      <div v-if="expanded">
        <router-link
          class="sidebar-item sidebar-item--child"
          v-for="subLink in link.children"
          :key="subLink.name"
          :to="subLink.path"
          active-class="sidebar-item--active sidebar-item--caret">
          <span class="sidebar-item__icon">
            {{ subLink.name.slice(0, 1) }}
          </span>
          <span class="sidebar-item__name">
            {{ subLink.name }}
          </span>
        </router-link>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import intersectionBy from 'lodash.intersectionby';

export default {
  name: 'SidebarItem',
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    isChildActive() {
      if (!this.link.children) {
        return false;
      }
      const matchedRoutes = this.$route.matched.map((obj) => obj.name);
      const checkRoutes = this.link.children.map((c) => c.path.name);
      return intersectionBy(matchedRoutes, checkRoutes).length > 0;
    },
  },
  methods: {
    toggleExpand() {
      if (!this.isChildActive) {
        this.expanded = !this.expanded;
      }
    },
  },
  watch: {
    isChildActive() {
      if (!this.expanded) {
        this.expanded = true;
      }
    },
  },
};
</script>
