const PHONE_UK = /^(0|\+44)\d{10}$/;
const POSTAL_CODE_UK =
  /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]0[Aa]{2})$/;
const NI_NUMBER = /^[A-Za-z]{2}\d{6}[A-Za-z]$/;
const NHS_NUMBER = /^\d{10}$/;

export { PHONE_UK, POSTAL_CODE_UK, NI_NUMBER, NHS_NUMBER };

export default {
  PHONE_UK,
  POSTAL_CODE_UK,
  NI_NUMBER,
  NHS_NUMBER,
};
