import axios from 'axios';

import { emptyToNull, nullToEmpty } from 'global-utils';

import profileTransforms from '../user/profile';

function transformResponse(user) {
  return nullToEmpty({
    email: user.email,
    isEmailVerified: user.is_email_verified,
    cwStatus: user.careworker.cw_status_mc_id,
    transportType: user.transport_type_mc_id,
    contractType: user.contract_type_mc_id,
    userName: user.user_name,
    profile: profileTransforms.transformResponse(user.profile),
  });
}
function transformRequest(user) {
  return emptyToNull({
    user: {
      email: user.email,
      profile: profileTransforms.transformRequest(user.profile),
    },
  });
}

async function getAll(params) {
  const url = 'api/cw';
  const response = await axios.get(url, { params });
  const results = response.data.results.map(transformResponse);
  results.total = response.data.count;
  return results;
}

async function get(userName) {
  const url = `api/cw/${userName}`;
  const response = await axios.get(url);

  return transformResponse(response.data.user);
}

async function put(userName, user) {
  const url = `api/cw/${userName}`;
  const request = transformRequest(user);
  await axios.put(url, request);
}

async function post(user) {
  const url = 'api/cw';
  const request = transformRequest(user);
  const response = await axios.post(url, request);
  return response.data.user_name;
}

export { getAll, get, put, post, transformResponse, transformRequest };
