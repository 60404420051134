import { Constant } from 'hcms-const-utils';
import VM_CODES from './vm_mc.json';
import VS_CODES from './mc.json';

const getConstUtils = (name, isVm = false) =>
  new Constant(
    (isVm ? VM_CODES : VS_CODES)
      .filter((op) => op.code_type === name)
      .map((op) => ({
        value: op.code_id,
        label: op.code_val,
        displayLabel: op.display_label,
        ...op.fe_meta,
      })),
  );

export const accountTypeUtils = getConstUtils('BANK_ACCOUNT_TYPES');
export const allocationPresetUtils = getConstUtils('ALLOCATION_PRESET');
export const allocationTypeUtils = getConstUtils('ALLOCATION_TYPE');
export const assistanceTypeUtils = getConstUtils('ASSISTANCE_TYPE');
export const attachmentTypeUtils = getConstUtils('ATTACHMENT_TYPE');
export const availabilityStatusUtils = getConstUtils('AVAILABILITY_STATUS');
export const biweeklyUtils = getConstUtils('BIWEEKLY');
export const bookingAllocationStatusUtils = getConstUtils(
  'BOOKING_ALLOCATION_RESULT',
);
export const bookingChangeStatusUtils = getConstUtils(
  'BOOKING_CHANGE_REQUEST_STATUS',
);
export const bookingChangeTypeUtils = getConstUtils(
  'BOOKING_CHANGE_REQUEST_TYPE',
);
export const bookingStatusUtils = getConstUtils('BOOKING_STATUS');
export const bookingTypeUtils = getConstUtils('BOOKING_TYPE');
export const careStatusUtils = getConstUtils('CARE_STATUS');
export const careWorkerStatusUtils = getConstUtils('CW_STATUS');
export const changeEventUtils = getConstUtils('SCR_EVENT_TYPE');
export const clientTypeUtils = getConstUtils('CLIENT_TYPE');
export const consumableTypeUtils = getConstUtils('CONSUMABLE_TYPE');
export const contactTypeUtils = getConstUtils('CONTACT_PERSON_TYPE');
export const contractTypeUtils = getConstUtils('EMPLOYMENT_CONTRACT_TYPE');
export const cwConsumablesUpdateTypeUtils = getConstUtils(
  'CW_CONSUMABLES_UPDATE_TYPE',
);
export const dataIssueUtils = getConstUtils('DATA_ISSUE');
export const dbsTypeUtils = getConstUtils('DBS_TYPE');
export const difficultyTypeUtils = getConstUtils('DIFFICULTY_TYPE');
export const dosageUnitUtils = getConstUtils('DOSAGE_UNIT');
export const emailProofPurposeUtils = getConstUtils('EMAIL_PROOF_PURPOSE');
export const employmentStatusUtils = getConstUtils('EMPLOYMENT_STATUS');
export const engineRunStatusUtils = getConstUtils('ENGINE_RUN_STATUS');
export const ethnicityUtils = getConstUtils('ETHNICITY');
export const formRequestSourceUtils = getConstUtils(
  'FORM_SUBMISSION_REQUEST_SOURCE',
);
export const formRequestStatusUtils = getConstUtils(
  'FORM_SUBMISSION_REQUEST_STATUS',
);
export const fundingTypeUtils = getConstUtils('FUNDING_TYPE');
export const genderUtils = getConstUtils('SEX');
export const interviewResultUtils = getConstUtils('INTERVIEW_DECISION');
export const invoiceStatusUtils = getConstUtils('INVOICE_STATUS');
export const journalFromUtils = getConstUtils('JOURNAL_FROM');
export const journalSubjectUtils = getConstUtils('JOURNAL_SUBJECT');
export const languageUtils = getConstUtils('LANGUAGE');
export const lateMedicationResponseUtils = getConstUtils(
  'LATE_MEDICATION_RESPONSE',
);
export const leaveStatusUtils = getConstUtils('LEAVE_STATUS');
export const leaveTypeUtils = getConstUtils('LEAVE_TYPE');
export const livingCircumstancesUtils = getConstUtils('LIVING_CIRCUMSTANCES');
export const maritalStatusUtils = getConstUtils('MARITAL_STATUS');
export const mealPreferenceUtils = getConstUtils('MEAL_PREFERENCE');
export const medicalConditionsUtils = getConstUtils('MEDICAL_CONDITIONS');
export const medicationRouteUtils = getConstUtils('MEDICATION_ROUTE');
export const medicationTypeUtils = getConstUtils('MEDICATION_TYPE');
export const mobilityEquipmentUtils = getConstUtils('MOBILITY_EQUIPMENT');
export const mobilityProfileUtils = getConstUtils('MOBILITY_PROFILE');
export const nationalityUtils = getConstUtils('NATIONALITY');
export const notificationPurposeUtils = getConstUtils('NOTIFICATION_PURPOSE');
export const notificationStatusUtils = getConstUtils('NOTIFICATION_STATUS');
export const notificationTypeUtils = getConstUtils('NOTIFICATION_TYPE');
export const parserStatusUtils = getConstUtils('PARSER_STATUS');
export const payFrequencyUtils = getConstUtils('PAY_FREQUENCY');
export const paymentModeUtils = getConstUtils('INVOICE_PAYMENT_MODE');
export const payslipStatusUtils = getConstUtils('PAYSLIP_STATUS');
export const performanceStatusUtils = getConstUtils('PERFORMANCE_STATUS');
export const preferenceIncorporationTypeUtils = getConstUtils(
  'PREFERENCE_INCORPORATION_TYPE',
);
export const preferenceTypeUtils = getConstUtils('PREFERENCE_TYPE');
export const preferredCwUtils = getConstUtils('HARD_PREFERRED_CW');
export const preLockStateUtils = getConstUtils('FIXED_CW');
export const recruitmentSourceUtils = getConstUtils('RECRUITMENT_SOURCE');
export const religionUtils = getConstUtils('RELIGION');
export const reminderStatusUtils = getConstUtils('REMINDER_STATUS');
export const scheduleChangeRequestTypeUtils = getConstUtils(
  'SCHEDULE_CHANGE_REQUEST_TYPE',
);
export const serviceUserStatusUtils = getConstUtils('SERVICE_USER_STATUS');
export const sexualOrientationUtils = getConstUtils('SEXUAL_ORIENTATION');
export const staffStatusUtils = getConstUtils('STAFF_STATUS');
export const statUtils = getConstUtils('STAT');
export const suspendStatusUtils = getConstUtils('SUSPEND_STATUS');
export const terminationStatusUtils = getConstUtils(
  'TERMINATION_REQUEST_STATUS',
);
export const titleUtils = getConstUtils('TITLE');
export const trainingSlotStatusUtils = getConstUtils('TRAINING_SLOT_STATUS');
export const trainingStatusUtils = getConstUtils('TRAINING_STATUS');
export const transportTypeUtils = getConstUtils('TRANSPORT_TYPE');
export const treatmentStatusUtils = getConstUtils('TREATMENT_STATUS');
export const vaccinationDoseUtils = getConstUtils('VACCINATION_DOSE');
export const visaTypeUtils = getConstUtils('VISA_PASSPORT_STATUS');
export const vmDataIssueUtils = getConstUtils('VM_DATA_ISSUE');
export const vmDataTypeUtils = getConstUtils('VM_DATA_TYPE');
export const vmMessageTypeUtils = getConstUtils('VM_MESSAGE_TYPE', false);

export const vmPreArrivalStatusUtils = getConstUtils(
  'PRE_ARRIVAL_STATUS',
  true,
);
export const vmBookingStatusUtils = getConstUtils('BOOKING_STATUS', true);
export const vmTaskCompletionUtils = getConstUtils('TASK_COMPLETION', true);
export const vmMedicationCompletionUtils = getConstUtils(
  'MEDICATION_COMPLETION',
  true,
);
export const vmIncidentTypeUtils = getConstUtils('INCIDENT_TYPE', true);
export const vmIncidentTreatmentUtils = getConstUtils('TREATMENT', true);
export const vmIncidentInjuryUtils = getConstUtils('INJURY', true);
export const vmIncidentLocationTypeUtils = getConstUtils('LOCATION_TYPE', true);
