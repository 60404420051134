import axios from 'axios';
import moment from 'moment';
import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';
import { formatTime } from 'global-utils';
import router from '@/router/index';
import { currentUser, logout } from 'auth-utils';

axios.defaults.baseURL = SERVER_ADDRESS;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Token ${currentUser.token}`;
axios.interceptors.response.use(undefined, async (error) => {
  try {
    await fetch(`${SERVER_ADDRESS}/utils`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors',
    });
  } catch (_) {
    if (router.currentRoute.name !== 'Offline') {
      router.push({ name: 'Offline' });
    }
    throw new Error(`Offline: ${formatTime(moment())}`);
  }
  if (error.response?.status === 403) {
    if (error.config.url.indexOf('api/auth') === -1) {
      await logout(true);
    }
  }
  if (!error.response) {
    if (error.config.url.indexOf('api/auth') === -1) {
      router.push({ name: 'Offline' });
      return;
    }

    throw new Error(`Offline: ${formatTime(moment())}`);
  }
  throw error;
});
