import { emit } from 'mixins/DataHook';
import { showNotification } from 'utils/notify';
import { notificationPurposeUtils } from 'hcms-mc-utils';
import { transformResponse as toNotification } from 'hcms-transforms/notifications';
import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';
import { currentUser } from 'auth-utils';

const url = new URL(SERVER_ADDRESS);
url.protocol = url.protocol.replace('http', 'ws');
url.pathname = '/api/sync';
url.searchParams.append('token', currentUser.token);

let socket;

const ACTION = notificationPurposeUtils.DICT.TASK;

const handleMessage = (message) => {
  const messageData = JSON.parse(message.data);

  const [event, data] = messageData;

  if (event === 'data-change') {
    const formattedData = {
      type: data.object_type,
      id: data.object_id,
      userName: data.user_name,
      requester: data.requester,
    };

    emit(formattedData.type, formattedData);
  }

  if (event === 'notification') {
    const notification = toNotification(data);
    showNotification({
      title: `New ${notification.purpose === ACTION ? 'Action' : 'Notification'}`,
      message: notification.description,
      duration: 9000,
      iconClass: notification.purpose === ACTION ? 'el-icon-time text-warning' : 'ti ti-info-alt text-success',
    });
    emit('Notification');
  }
};

const establishSocket = () => {
  socket = new WebSocket(url);

  socket.addEventListener('message', handleMessage);
  socket.addEventListener('close', () => {
    socket.removeEventListener('message', handleMessage);
    window.setTimeout(() => {
      establishSocket();
    }, 2000);
  });
};

establishSocket();
