import ImportedVue from 'vue';
import Overlay from './Overlay.vue';

const OverlayComponent = ImportedVue.extend(Overlay);

function toggleLock(elem, toggle, propName = 'overlay') {
  const overlay = new OverlayComponent().$mount();

  if (toggle && !elem[propName]) {
    elem.originalStyle = {
      overflow: elem.style.overflow,
      position: elem.style.position,
    };
    elem.style.overflow = 'hidden';
    elem.style.position = 'relative';
    elem[propName] = overlay;
    elem.className += ' lock';
    elem.appendChild(overlay.$el);
    if (typeof toggle === 'string') {
      overlay.$props.reason = toggle;
    }
    return;
  }

  if (!toggle && elem[propName]) {
    elem.style.overflow = elem.originalStyle.overflow;
    elem.style.position = elem.originalStyle.position;
    elem.className = elem.className.replace(/lock/g, '');

    elem.removeChild(elem[propName].$el);
    delete elem[propName];
  }
}

const Plugin = {
  install(Vue) {
    Vue.directive('lock', {
      bind(el, binding) {
        if (binding.value) {
          toggleLock(el, binding.value);
        }
      },

      update(el, binding) {
        if (binding.oldValue !== binding.value) {
          toggleLock(el, binding.value);
        }
      },

      unbind(el) {
        toggleLock(el, false);
      },
    });
  },
};

export default Plugin;

export { Plugin, toggleLock };
