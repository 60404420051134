import { equalJSON } from 'global-utils';
import { SET_CONSTANT } from './mutationTypes';

export default {
  [SET_CONSTANT]: (state, { type, value }) => {
    if (!equalJSON(state[type], value)) {
      state[type] = value;
    }
  },
};
