<template>
  <el-select v-model="model" placeholder=" " v-bind="$attrs">
    <template v-slot:prefix>
      <i class="el-icon-time"></i>
    </template>
    <el-option v-for="op in options" :key="op.value" :value="op.value" :label="op.label" />
  </el-select>
</template>
<script>
import moment from 'moment';

export default {
  name: 'TimeSelect',

  props: {
    value: {
      required: true,
    },
    pickerOptions: {
      type: Object,
      default: () => ({
        start: '06:00',
        step: '00:30',
        end: '23:00',
      }),
    },
  },

  computed: {
    options() {
      const { start, step, end } = this.pickerOptions;

      const stepInMinutes = step ? moment.duration(step).asMinutes() : 30;

      const options = [];

      const [startH, startM] = start.split(':').map((n) => parseInt(n, 10));
      const [endH, endM] = end.split(':').map((n) => parseInt(n, 10));
      const steps = ((endH - startH) * 60 + (endM - startM)) / stepInMinutes;

      for (let i = 0; i <= steps; i += 1) {
        const timeInMinutes = startH * 60 + startM + i * stepInMinutes;
        const time = [Math.trunc(timeInMinutes / 60), timeInMinutes % 60]
          .map((n) => n.toString().padStart(2, '0'))
          .join(':');
        options.push(time);
      }

      return options.map((option) => ({
        value: option,
        label: option,
      }));
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
