import {
  cloneJSON,
  emptyToNull,
  equalJSON,
  formatDate,
  formatDateRange,
  formatDateTimeZ,
  formatDuration,
  formatHalfRange,
  formatTime,
  generateRandomNumbers,
  getHalf,
  getStatusClass,
  isNumber,
  nullToEmpty,
  setDate,
  setTime,
  sleep,
} from './methods';

const Plugin = {
  install(Vue) {
    /* eslint-disable no-param-reassign */
    Vue.prototype.cloneJSON = cloneJSON;
    Vue.prototype.emptyToNull = emptyToNull;
    Vue.prototype.equalJSON = equalJSON;
    Vue.prototype.formatDate = formatDate;
    Vue.prototype.formatDateRange = formatDateRange;
    Vue.prototype.formatDateTimeZ = formatDateTimeZ;
    Vue.prototype.formatDuration = formatDuration;
    Vue.prototype.formatHalfRange = formatHalfRange;
    Vue.prototype.formatTime = formatTime;
    Vue.prototype.generateRandomNumbers = generateRandomNumbers;
    Vue.prototype.getHalf = getHalf;
    Vue.prototype.getStatusClass = getStatusClass;
    Vue.prototype.isNumber = isNumber;
    Vue.prototype.nullToEmpty = nullToEmpty;
    Vue.prototype.setDate = setDate;
    Vue.prototype.setTime = setTime;
    Vue.prototype.sleep = sleep;
    /* eslint-enable no-param-reassign */
  },
};

export default Plugin;

export * from './methods';
export { Plugin };
