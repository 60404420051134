import { get as getAttachment } from 'hcms-transforms/attachment';

import { SET_ATTACHMENT, SET_PROMISE } from './mutationTypes';

export default {
  getAttachment: async ({ commit, state }, attachmentId) => {
    if (Object.prototype.hasOwnProperty.call(state.attachment, attachmentId)) {
      return state.attachment[attachmentId];
    }
    if (Object.prototype.hasOwnProperty.call(state.promise, attachmentId)) {
      await state.promise[attachmentId];
      return state.attachment[attachmentId];
    }
    const promise = getAttachment(attachmentId);
    commit(SET_PROMISE, promise);
    const response = await promise;
    commit(SET_ATTACHMENT, response);
    return state.attachment[attachmentId];
  },
};
