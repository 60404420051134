<template>
  <span>
    <el-popover
      trigger="hover"
      :disabled="!user || isInternal"
      :open-delay="500"
      :close-delay="500"
      :placement="placement"
      @show="showContact = true"
      @after-leave="showContact = false">
      <span slot="reference" :class="{ 'text-muted': !user }">
        <slot>
          <slot name="icon">
            <i class="ti ti-user" v-if="!isInternal"></i>
          </slot>
          {{ nameOfUser }}
        </slot>
      </span>
      <contact-card v-if="showContact" :userName="userName"></contact-card>
    </el-popover>
  </span>
</template>
<script>
import { roleUtils } from 'hcms-const-utils';
import Vue from 'vue';
import { Popover, Avatar } from 'element-ui';

Vue.use(Popover);
Vue.use(Avatar);

const ContactCard = () => import(/* webpackChunkName:"ContactCard" */ './ContactCard.vue');

const toShortRole = (role) => roleUtils.getByValue(role).meta.shortName;

export default {
  name: 'Contact',
  components: {
    ContactCard,
  },
  props: {
    userName: {
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showFirstName: {
      type: Boolean,
      default: true,
    },
    showMiddleName: {
      type: Boolean,
      default: false,
    },
    showRole: {
      type: Boolean,
    },
    showAlias: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      showContact: '',
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser(this.userName);
    },
    isInternal() {
      return this.user && roleUtils.toMeta(this.user.role).isInternal;
    },
    nameOfUser() {
      const { user } = this;
      if (!user) {
        return 'User not specified';
      }

      const nameComponents = [];

      if (this.showRole) {
        nameComponents.push(`${toShortRole(user.role)}:`);
      }
      if (this.showTitle) {
        nameComponents.push(user.title);
      }

      if (this.showFirstName) {
        nameComponents.push(user.firstName);
      }
      if (this.showMiddleName && user.middleName) {
        nameComponents.push(user.middleName);
      }
      nameComponents.push(user.lastName);

      if (this.showAlias && user.alias) {
        nameComponents.push(`(${user.alias})`);
      }

      return nameComponents.join(' ');
    },
  },
};
</script>
