import { Loading } from 'element-ui';

import { roleUtils } from 'hcms-const-utils';

import { get as getUser, getAll as getUsers } from 'hcms-transforms/user_list';

import { UPDATE_USERS, UPDATE_USER, SET_PROMISE } from './mutationTypes';

const STAFF_ROLES = roleUtils.OPTIONS.filter((op) => op.meta.isStaff).map((op) => op.value);

export default {
  async getUsers({ commit, state }, force) {
    if (force || state.promise.users === null) {
      const loading = Loading.service({ text: 'Fetching Data' });
      try {
        const promise = getUsers();
        commit(SET_PROMISE, { userType: 'users', promise });

        const userList = await promise;
        commit(UPDATE_USERS, userList);
        loading.close();
      } catch (err) {
        commit(SET_PROMISE, { userType: 'users', promise: null });
        loading.close();
      }
    } else {
      await state.promise.users;
    }
  },
  async getUser({ commit }, userName) {
    const user = await getUser(userName);
    commit(UPDATE_USER, user);
  },
  async getCareWorkers({ commit, state }, force) {
    if (force || state.promise.careWorkers === null) {
      const loading = Loading.service({ text: 'Fetching Data' });
      try {
        const params = {
          role_id: roleUtils.DICT.CW,
        };
        const promise = getUsers(params);
        commit(SET_PROMISE, { userType: 'careWorkers', promise });

        const userList = await promise;
        commit(UPDATE_USERS, userList);
        loading.close();
      } catch (err) {
        commit(SET_PROMISE, { userType: 'careWorkers', promise: null });
        loading.close();
      }
    } else {
      await state.promise.careWorkers;
    }
  },
  async getServiceUsers({ commit, state }, force) {
    if (force || state.promise.serviceUsers === null) {
      const loading = Loading.service({ text: 'Fetching Data' });
      try {
        const params = {
          role_id: roleUtils.DICT.SU,
        };
        const promise = getUsers(params);
        commit(SET_PROMISE, { userType: 'serviceUsers', promise });

        const userList = await promise;
        commit(UPDATE_USERS, userList);
        loading.close();
      } catch (err) {
        commit(SET_PROMISE, { userType: 'serviceUsers', promise: null });
        loading.close();
      }
    } else {
      await state.promise.serviceUsers;
    }
  },
  async getStaff({ commit, state }, force) {
    if (force || state.promise.staff === null) {
      const loading = Loading.service({ text: 'Fetching Data' });
      try {
        const params = {
          role_id: STAFF_ROLES,
        };
        const promise = getUsers(params);
        commit(SET_PROMISE, { userType: 'staff', promise });

        const userList = await promise;
        commit(UPDATE_USERS, userList);
        loading.close();
      } catch (err) {
        commit(SET_PROMISE, { userType: 'staff', promise: null });
        loading.close();
      }
    } else {
      await state.promise.staff;
    }
  },
};
