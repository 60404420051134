import { CLEAR_CACHE, SET_ATTACHMENT, SET_PROMISE } from './mutationTypes';

export default {
  [CLEAR_CACHE]: (state) => {
    state.attachment = {};
    state.promise = {};
  },
  [SET_ATTACHMENT]: (state, attachment) => {
    state.attachment = {
      ...state.attachment,
      [attachment.attachmentId]: attachment,
    };
  },
  [SET_PROMISE]: async (state, { attachmentId, promise }) => {
    state.promise[attachmentId] = promise;
    await promise;
    delete state.promise[attachmentId];
  },
};
