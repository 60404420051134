<template>
  <nav class="top-navbar" :class="this.$route.meta.navbarClass">
    <div class="top-navbar__content">
      <div class="top-navbar__left">
        <button
          @click="toggleSidebar"
          class="dashboard__collapse-btn btn btn-icon btn-simple ti"
          :class="sidebarCollapsed ? 'ti-list' : 'ti-more'"></button>
        <button @click.stop="toggleMobileNav" class="mobile-nav-btn btn btn-simple btn-sm ti ti-menu"></button>
        <div class="top-navbar__title flex-grow-1">
          {{ $route.name }}
        </div>
      </div>
      <div class="top-navbar__right">
        <div v-if="isDebug" class="mr-2 px-2 text-right text-muted">
          <div>System Time: {{ formatDateTimeZ($store.state.constants.NOW) }}</div>
          <div>Page Refreshed: {{ formatDateTimeZ(lastRefreshedOn) }}</div>
        </div>
        <el-tooltip
          v-if="$store.state.engine.bookingGenerationStatus.isRunning"
          content="Booking generation in progress">
          <div v-loading="true" class="mt-1 mr-4 pa-4"></div>
        </el-tooltip>
        <el-tooltip v-if="runningPercentage">
          <template v-slot:content>
            <div class="text-center">
              Allocation in Progress <br />
              Allocated {{ report.allocatedCount }}/{{ report.bookingCount }}
              Bookings
            </div>
          </template>
          <router-link class="mr-4" :to="{ name: 'AI Scheduler' }">
            <ElProgress
              class="mt-1"
              type="circle"
              color="#458781"
              :stroke-width="2"
              :width="20"
              :percentage="runningPercentage"
              :show-text="false" />
          </router-link>
        </el-tooltip>
        <el-dropdown trigger="click" @command="showNotificationDetails">
          <div
            class="notification-bell pa-2 mr-4"
            :title="`${actionsCount} Actions Pending`"
            v-loading="loading.actions">
            <el-badge :value="actionsCount" :hidden="actionsCount === 0">
              <i class="ti ti-bell"></i>
            </el-badge>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(notification, index) in actions" :key="index" :command="notification">
              <span :class="getNotificationClass(notification)">
                {{ notification.description }}
              </span>
            </el-dropdown-item>
            <el-dropdown-item v-if="actions.length === 0">
              <a disabled>No new actions</a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown trigger="click" @command="handleCommand">
          <div class="user-dropdown">
            <i class="ti ti-user"></i>
            {{ nameOfUser }}
            <i class="ti ti-angle-down"></i>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="APPLY_LEAVE">
              <i class="ti ti-plus"></i>
              Apply Leave
            </el-dropdown-item>
            <el-dropdown-item v-if="isMainStaff" :command="MY_PROFILE">
              <i class="ti ti-user"></i>
              My Profile
            </el-dropdown-item>
            <el-dropdown-item :command="LOG_REQUEST">
              <i class="ti ti-share"></i>
              Log Request
            </el-dropdown-item>
            <el-dropdown-item v-if="$store.getters.hasFormAccess" :command="SUBMIT_FORM">
              <i class="ti ti-pencil-alt"></i>
              My Forms
            </el-dropdown-item>
            <el-dropdown-item v-if="currentUser.isSuperUser" :command="ADMIN">
              <i class="ti ti-settings"></i>
              User Admin
            </el-dropdown-item>
            <el-dropdown-item :command="LOGOUT">
              <i class="ti ti-shift-right"></i>
              Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </nav>
</template>
<script>
import { Dropdown, DropdownItem, DropdownMenu, Progress } from 'element-ui';
import moment from 'moment';

import { LOG_REQUEST as LOG_REQUEST_PATH, NEW_FORM_SUBMISSION, SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';
import { getAll as getNotifications } from 'hcms-transforms/notifications';
import { openDetails } from 'utils/notificationUtils';

import { currentUser, logout } from 'auth-utils';
import { notificationPurposeUtils, notificationStatusUtils } from 'hcms-mc-utils';

const NewLeave = () => import(/* webpackChunkName:"NewLeave" */ 'views/Staff/Cards/NewLeave.vue');

const LOG_REQUEST = 'LOG_REQUEST';
const FORMS = 'FORMS';
const SUBMIT_FORM = 'SUBMIT_FORM';
const ADMIN = 'ADMIN';
const APPLY_LEAVE = 'APPLY_LEAVE';
const MY_PROFILE = 'MY_PROFILE';
const LOGOUT = 'LOGOUT';

const { ELEVATED } = notificationStatusUtils.DICT;
const { NEW } = notificationStatusUtils.DICT;

const ACTION = notificationPurposeUtils.DICT.TASK;

const lastRefreshedOn = moment();

export default {
  name: 'TopNavBar',

  props: {
    sidebarCollapsed: {
      type: Boolean,
    },
  },

  components: {
    ElDropdown: Dropdown,
    ElDropdownMenu: DropdownMenu,
    ElDropdownItem: DropdownItem,
    ElProgress: Progress,
  },

  constants: {
    isDebug: process.env.VUE_APP_SHOW_NAV_CLOCK === 'true',
    LOG_REQUEST,
    FORMS,
    SUBMIT_FORM,
    ADMIN,
    LOGOUT,
    APPLY_LEAVE,
    MY_PROFILE,
    isMainStaff: currentUser.roleConst?.meta?.isStaff,
    currentUser,
    lastRefreshedOn,
  },

  data() {
    return {
      loading: {
        actions: false,
      },

      urgentCount: 0,
      actionsCount: 0,
      actions: [],
    };
  },

  computed: {
    NOW() {
      return this.$store.state.constants.NOW;
    },
    nameOfUser() {
      return this.$store.getters.getName(currentUser.userName);
    },
    report() {
      return this.$store.state.engine.lastReport;
    },
    runningPercentage() {
      const { isRunning } = this.$store.getters;
      if (isRunning) {
        return Number(((this.report.allocatedCount / this.report.bookingCount) * 100).toFixed());
      }
      return false;
    },
  },

  methods: {
    toggleSidebar() {
      this.$emit('toggleSidebar');
    },
    toggleMobileNav() {
      this.$emit('toggleMobileNav', true);
    },
    handleCommand(command) {
      if (command === LOGOUT) {
        this.logout();
      } else if (command === LOG_REQUEST) {
        window.open(LOG_REQUEST_PATH, '_blank');
      } else if (command === SUBMIT_FORM) {
        window.open(`${NEW_FORM_SUBMISSION}/requests`, '_blank');
      } else if (command === ADMIN) {
        window.open(`${SERVER_ADDRESS}/admin`, '_blank');
      } else if (command === MY_PROFILE) {
        this.$router.push({
          name: 'My Profile',
        });
      } else if (command === APPLY_LEAVE) {
        this.$showModal({
          component: NewLeave,
          props: {
            userName: currentUser.userName,
          },
        });
      }
    },

    async logout() {
      await logout();
    },

    async showNotificationDetails(notification) {
      await openDetails.call(this, notification);
    },

    async getNotifications() {
      this.loading.actions = true;
      try {
        const response = await getNotifications({
          limit: 10,
          sort: '-remind_on,event_date',
          notification_status: [ELEVATED, NEW].toString(),
          purpose: ACTION,
        });
        this.actions = response.data.results;
        this.actionsCount = response.data.count;
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading.actions = false;
    },

    async getUrgentCount() {
      try {
        const response = await getNotifications({
          event_date_gte: this.NOW.toISOString(),
          event_date_lte: moment(this.NOW).add(6, 'hours').toISOString(),
          purpose: ACTION,
          limit: 0,
          notification_status: [ELEVATED, NEW].toString(),
        });
        this.urgentCount = response.data.count;
      } catch (err) {
        this.$notify(err, 'error');
      }
    },

    getNotificationClass(notification) {
      if (notification.seenDate) {
        return 'text-muted';
      }
      return '';
    },
  },

  async created() {
    this.getNotifications();
    this.getUrgentCount();
  },

  dataHooks: {
    Notification() {
      this.getNotifications();
      this.getUrgentCount();
    },
  },

  watch: {
    '$store.state.constants.START_OF_FIVE_MINUTES': {
      handler() {
        if (this.urgentCount) {
          this.$notify('You have urgent actions pending.', 'warning');
        }
      },
    },
  },
};
</script>
<style lang="scss">
.top-navbar {
  .notification-bell {
    font-size: 18px;
    color: #212121;
  }

  .user-dropdown {
    color: #212121;
    font-size: 18px;

    .ti-angle-down {
      font-size: 12px;
    }
  }

  .loading-overlay {
    background: transparent;
  }
}
</style>
