import { roleUtils } from 'hcms-const-utils';
import { currentUser } from 'auth-utils';

const ROLES = roleUtils.DICT;

const isAdmin = currentUser.role === ROLES.MGT;

const { SUP, MGT, HR, CTR, FIN } = ROLES;

const checkAccess = isAdmin ? () => true : (PERMISSIONS) => PERMISSIONS.includes(currentUser.role);

export default {
  'Overview': true,

  'Staff List': checkAccess([MGT, HR]),
  'Staff Details': checkAccess([MGT, HR]),
  'Staff Personal Info': checkAccess([MGT, HR]),
  'Staff Leave': checkAccess([MGT, HR]),
  'Staff Recruitment': checkAccess([MGT, HR]),
  'Staff Attachments': checkAccess([MGT, HR]),
  'Staff Visits': checkAccess([MGT]),

  'Care Worker List': checkAccess([MGT, CTR, SUP, HR, FIN]),
  'Care Worker Details': checkAccess([MGT, CTR, SUP, HR, FIN]),
  'CW Details Redirect': true,
  // TODO: set permissions for Payslips
  'Manage Payslips': checkAccess([FIN]),
  'Care Worker Overview': checkAccess([MGT, CTR, SUP, HR]),
  'Care Worker Recruitment': checkAccess([MGT, CTR, SUP, HR, FIN]),
  'Care Worker Personal Info': checkAccess([MGT, CTR, SUP, HR, FIN]),
  'Care Worker Skills': checkAccess([MGT, CTR, SUP, HR]),
  'Care Worker Leave': checkAccess([MGT, CTR, SUP, HR]),
  'Care Worker Service': checkAccess([MGT, CTR, SUP, HR]),
  'Care Worker Roster': checkAccess([MGT, CTR, SUP, HR, FIN]),
  'Care Worker Attachments': checkAccess([HR]),
  'Care Worker Payslips': checkAccess([FIN]),

  'Service User List': checkAccess([MGT, CTR, SUP, FIN]),
  'Service User Details': checkAccess([MGT, CTR, SUP, FIN]),
  // TODO: set permissions for Pricing Plans, Invoices
  'Service User Pricing Plans': checkAccess([FIN]),
  'Manage Invoices': checkAccess([FIN]),
  'Service User Overview': checkAccess([MGT, CTR, SUP]),
  'Service User Personal Info': checkAccess([MGT, CTR, SUP, FIN]),
  'Service User Bookings': checkAccess([MGT, CTR, SUP, FIN]),
  'Service User Observations': checkAccess([MGT, CTR, SUP]),
  'Service User Medical': checkAccess([MGT, CTR, SUP]),
  'Service User Care': checkAccess([MGT, CTR, SUP, FIN]),
  'Service User Attachments': checkAccess([CTR, SUP, MGT]),
  'Service User Invoices': checkAccess([FIN]),

  'Bookings Block View': checkAccess([MGT, CTR, HR, SUP, FIN]),
  'Bookings Compare View': checkAccess([MGT, CTR, HR, SUP, FIN]),
  'Bookings List View': checkAccess([MGT, CTR, HR, SUP, FIN]),
  'AI Scheduler': checkAccess([CTR]),

  'Form Templates': true,
  'All Form Submissions': true,
  'Form Submission Requests': true,
  'Form Submissions': true,

  'My Profile': true,
  'My Personal Info': true,
  'My Leaves': true,
  'My Recruitment': true,

  'Notifications': checkAccess([MGT, FIN, CTR, HR, SUP]),
  'Actions': checkAccess([MGT, FIN, CTR, HR, SUP]),
  'Consumables': checkAccess([SUP]),
  'Remarks': checkAccess([SUP]),
  'Registration': checkAccess([SUP]),
  'Visited Bookings': checkAccess([SUP]),

  'Not Found': true,
  'Offline': true,
};
