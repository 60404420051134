import moment from 'moment';

export default {
  transformResponse(profile) {
    return {
      title: profile.title_mc_id,
      firstName: profile.forename,
      middleName: profile.middle_name,
      lastName: profile.surname,
      alias: profile.alias,
      dob: profile.dob ? moment(profile.dob) : undefined,
      telephone1: profile.telephone_1,
      telephone2: profile.telephone_2,
      maritalStatus: profile.marital_status_mc_id,
      nationality: profile.nationality_mc_id,
      niNumber: profile.ni_number,
      nhsNo: profile.nhs_no,
      profileImageId: profile.profile_image_id
        ? [profile.profile_image_id]
        : [],
      isSmoker: profile.is_non_smoker,
      sex: profile.sex_mc_id,
      religion: profile.religion_mc_id,
      ethnicity: profile.ethnicity_mc_id,
      primaryLanguage: profile.primary_language_mc_id,
      secondaryLanguage: profile.secondary_language_mc_id,
      contacts: profile.contact,
    };
  },
  transformRequest(profile) {
    return {
      title_mc_id: profile.title,
      forename: profile.firstName,
      middle_name: profile.middleName,
      surname: profile.lastName,
      alias: profile.alias,
      dob: profile.dob,
      telephone_1: profile.telephone1,
      telephone_2: profile.telephone2,
      marital_status_mc_id: profile.maritalStatus,
      nationality_mc_id: profile.nationality,
      ni_number: profile.niNumber,
      nhs_no: profile.nhsNo,
      is_non_smoker: profile.isSmoker,
      profile_image_id: Array.isArray(profile.profileImageId)
        ? profile.profileImageId[0] || ''
        : undefined,
      sex_mc_id: profile.sex,
      religion_mc_id: profile.religion,
      ethnicity_mc_id: profile.ethnicity,
      primary_language_mc_id: profile.primaryLanguage,
      secondary_language_mc_id: profile.secondaryLanguage,
      contact: profile.contacts,
    };
  },
};
