import { currentUser } from 'auth-utils';
import { roleUtils } from 'hcms-const-utils';
import moment from 'moment';
import { MINUTE } from 'tz-utils';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const NOW = moment();
const START_OF_HOUR = moment(NOW).startOf('hour');
const MS_SINCE_HOUR = moment(NOW).diff(START_OF_HOUR, 'ms');
const START_OF_FIVE_MINUTES = moment(NOW).subtract(MS_SINCE_HOUR % (5 * MINUTE), 'ms');
const IN15 = moment(START_OF_FIVE_MINUTES).add(15, 'minutes');
const START_OF_CURRENT_HALF = moment(START_OF_HOUR).subtract(NOW.hours() % 12, 'hours');
const END_OF_CURRENT_HALF = moment(START_OF_CURRENT_HALF).add(11, 'hours').endOf('hour');
const TODAY = moment(NOW).startOf('day');
const END_OF_TODAY = moment(NOW).endOf('day');
const CURRENT_SESSION_START = moment(NOW).startOf('week');
const CURRENT_SESSION_END = moment(NOW).endOf('week');
const NEXT_SESSION_START = moment(CURRENT_SESSION_END).add(1, 'ms');
const NEXT_SESSION_END = moment(CURRENT_SESSION_END).add(1, 'week');
const BOOKINGS_GENERATED_UNTIL = moment(NEXT_SESSION_END).add(1, 'week');
const NON_CRITICAL_PERIOD_START = moment(NEXT_SESSION_END).add(1, 'ms');
const ALERT_PERIOD = moment(NEXT_SESSION_END).add(currentUser.role === roleUtils.DICT.HR ? 1 : 0, 'week');
const NOTIFIED_PERIOD_END = moment();

const state = {
  CURRENT_SESSION_START,
  CURRENT_SESSION_END,
  NEXT_SESSION_START,
  NEXT_SESSION_END,
  NON_CRITICAL_PERIOD_START,
  BOOKINGS_GENERATED_UNTIL,
  ALERT_PERIOD,
  NOTIFIED_PERIOD_END,
  NOW,
  START_OF_HOUR,
  START_OF_FIVE_MINUTES,
  IN15,
  START_OF_CURRENT_HALF,
  END_OF_CURRENT_HALF,
  TODAY,
  END_OF_TODAY,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
