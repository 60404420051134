import BE_CLASSES from 'hcms-constants/BE_CLASSES';

const StaffLeave = () => import(/* webpackChunkName:"Leave" */ 'views/Staff/Cards/LeaveModal.vue');
const Leave = () => import(/* webpackChunkName:"Leave" */ 'views/CareWorker/Modals/Leave.vue');
const Certificate = () => import(/* webpackChunkName:"Certificate" */ 'views/CareWorker/Modals/Certificate.vue');
const Training = () => import(/* webpackChunkName:"Training" */ 'views/CareWorker/Modals/Training.vue');
const Booking = () => import(/* webpackChunkName:"Booking" */ 'views/Modals/Booking.vue');
const BookingChangeRequest = () =>
  import(/* webpackChunkName:"BookingChangeRequest" */ 'views/Modals/BookingChangeRequest.vue');
const UpdatedAvailability = () =>
  import(/* webpackChunkName:"UpdatedAvailability" */ 'views/CareWorker/Modals/UpdatedAvailability.vue');
const StaffSuspension = () => import(/* webpackChunkName:"StaffSuspension" */ 'views/Staff/Cards/Suspension.vue');
const CwSuspension = () => import(/* webpackChunkName:"SuspensionModal" */ 'views/CareWorker/Modals/Suspension.vue');
const CwTerminate = () => import(/* webpackChunkName:"CwTerminate" */ 'views/CareWorker/Modals/Terminate.vue');
const CarePlan = () => import(/* webpackChunkName:"CarePlan" */ 'views/ServiceUser/Cards/CarePlan.vue');
const CancelSuspendBookings = () =>
  import(/* webpackChunkName:"CancelSuspendBookings" */ 'views/ServiceUser/Modals/CancelSuspendBookings.vue');
const Reminder = () => import(/* webpackChunkName:"CancelSuspendBookings" */ 'views/Modals/Reminder.vue');
const HandleAddressBookings = () =>
  import(/* webpackChunkName:"HandleAddressBookings" */ 'views/ServiceUser/Modals/HandleAddressBookings.vue');
const MedicalCondition = () =>
  import(/* webpackChunkName:"MedicalCondition" */ 'views/ServiceUser/Modals/MedicalCondition.vue');
const Medication = () => import(/* webpackChunkName:"Medication" */ 'views/ServiceUser/Modals/Medication.vue');
const FormRequest = () => import(/* webpackChunkName:"FormRequest" */ 'views/Modals/FormRequest.vue');
const MedicationIssueLog = () =>
  import(/* webpackChunkName:"MedicationIssueLog" */ 'views/Common/Cards/MedicationIssueLog.vue');

const classNameToModal = (className, userName, id) => {
  switch (className) {
    case BE_CLASSES.STAFF_LEAVE:
      return {
        component: StaffLeave,
        props: {
          userName,
          leaveId: id,
        },
      };
    case BE_CLASSES.STAFF_SUSPEND:
      return {
        component: StaffSuspension,
        props: {
          userName,
          suspensionId: id,
        },
      };
    case BE_CLASSES.CW_AVAILABILITY:
      return {
        component: UpdatedAvailability,
        props: {
          userName,
          availabilityId: id,
        },
      };
    case BE_CLASSES.CW_LEAVE:
      return {
        component: Leave,
        props: {
          userName,
          leaveId: id,
        },
      };

    case BE_CLASSES.CW_CERTIFICATE:
      return {
        component: Certificate,
        props: {
          userName,
          certificateId: id,
        },
      };

    case BE_CLASSES.CW_TRAINING:
      return {
        component: Training,
        props: {
          userName,
          trainingId: id,
        },
      };

    case BE_CLASSES.CW_SUSPEND:
      return {
        component: CwSuspension,
        props: {
          userName,
          suspensionId: id,
        },
      };
    case BE_CLASSES.CW_TERMINATE:
      return {
        component: CwTerminate,
        props: {
          userName,
          terminationId: id,
        },
      };

    case BE_CLASSES.BOOKING:
      return {
        component: Booking,
        props: {
          bookingId: id,
        },
      };
    case BE_CLASSES.BOOKING_CHANGE_REQUEST:
      return {
        component: BookingChangeRequest,
        props: {
          changeRequestId: id,
        },
      };

    case BE_CLASSES.SU_SUSPEND:
      return {
        component: CancelSuspendBookings,
        props: {
          userName,
          suspensionId: id,
        },
      };
    case BE_CLASSES.SU_CARE_PLAN:
      return {
        component: CarePlan,
        props: {
          userName,
          carePlanId: id,
        },
      };
    case BE_CLASSES.REMINDER:
      return {
        component: Reminder,
        props: {
          reminderId: id,
        },
      };
    case BE_CLASSES.SU_ADDRESS:
      return {
        component: HandleAddressBookings,
        props: {
          userName,
          upcomingAddressId: id,
        },
      };
    case BE_CLASSES.MEDICATION:
      return {
        component: Medication,
        props: {
          userName,
          medicationId: id,
        },
      };
    case BE_CLASSES.MEDICATION_ISSUE_LOG:
      return {
        component: MedicationIssueLog,
        props: {
          userName,
          medicationIssueLogId: id,
        },
      };
    case BE_CLASSES.MEDICAL_CONDITION:
      return {
        component: MedicalCondition,
        props: {
          userName,
          medicalConditionId: id,
        },
      };

    case BE_CLASSES.FORM_REQUEST:
      return {
        component: FormRequest,
        props: {
          formRequestId: id,
        },
      };
    default:
      return false;
  }
};

export { classNameToModal };
