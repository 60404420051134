import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const defaultState = {
  users: {},
  promise: {
    users: null,
    careWorkers: null,
    serviceUsers: null,
    staff: null,
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
