import moment from 'moment';
import { engineRunStatusUtils } from 'hcms-mc-utils';
import { SECOND } from 'tz-utils';
import { sleep } from 'global-utils';
import { getAll as getEngineRuns, get as getEngineRun } from 'hcms-transforms/engine';

import { get as getBookingGenStatus } from 'hcms-transforms/booking_generation_status';

import { SET_LAST_REPORT, UPDATE_LAST_REPORT, CLEAR_LAST_REPORT, SET_BOOKING_GENERATION_STATUS } from './mutationTypes';

const ENGINE_RUN_APPROVED = engineRunStatusUtils.DICT.APPROVED;
const ENGINE_RUN_PENDING = engineRunStatusUtils.DICT.PENDING;
const ENGINE_RUN_RUNNING = engineRunStatusUtils.DICT.RUNNING;

async function getLastEngineRun({ rootState, commit, dispatch }) {
  const engineRuns = await getEngineRuns({
    sort: '-created_date',
    start_date_gte: moment(rootState.constants.CURRENT_SESSION_START).subtract(1, 'week').toISOString(),
    limit: 1,
  });

  const report = engineRuns[0];
  if (report && [ENGINE_RUN_APPROVED, ENGINE_RUN_PENDING, ENGINE_RUN_RUNNING].includes(report.engineRunStatus)) {
    commit(SET_LAST_REPORT, report);
    dispatch('getEngineProgress', report.reportId);
  } else {
    commit(CLEAR_LAST_REPORT);
  }
}

async function getEngineProgress({ commit, state, dispatch }, reportId) {
  const report = state.lastReport;
  if (report.reportId === reportId && report.engineRunStatus === ENGINE_RUN_RUNNING && !report.isTerminated) {
    // eslint-disable-next-line no-await-in-loop
    const progressReport = await getEngineRun(reportId);
    commit(UPDATE_LAST_REPORT, progressReport);
    await sleep(SECOND);

    dispatch('getEngineProgress', reportId);
  }
}

async function getBookingGenerationStatus({ commit }) {
  const status = await getBookingGenStatus();
  commit(SET_BOOKING_GENERATION_STATUS, status);
}

export default {
  getLastEngineRun,
  getEngineProgress,
  getBookingGenerationStatus,
};
