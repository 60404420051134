<template>
  <div>
    <date-picker v-if="!isDisabled" v-bind="$attrs" v-model="dateRange" type="daterange"> </date-picker>
    <span v-else>
      {{ formatDateRange(dateRange[0], dateRange[1]) }}
    </span>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DateRange',
  props: {
    startDate: {
      required: true,
    },
    endDate: {
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    future: {
      type: Boolean,
    },
  },
  inject: {
    elForm: {
      default: () => ({}),
    },
  },

  computed: {
    isDisabled() {
      return this.elForm.disabled || this.disabled;
    },
    dateRange: {
      get() {
        return [this.startDate || '', this.endDate || ''];
      },
      set(value) {
        if (Array.isArray(value)) {
          const [fromDate, toDate] = value;
          if (this.future) {
            const NOW = moment();
            const selectedDate = fromDate.startOf('day');
            this.$emit('update:startDate', NOW > selectedDate ? NOW : selectedDate);
          } else {
            this.$emit('update:startDate', fromDate.startOf('day'));
          }
          this.$emit('update:endDate', toDate.endOf('day'));
        } else {
          this.$emit('update:startDate', null);
          this.$emit('update:endDate', null);
        }
        this.$emit('change');
      },
    },
  },
};
</script>
