import axios from 'axios';
import moment from 'moment';

function transformResponse(notification) {
  return {
    id: notification.notification_id,
    label: notification.label,
    description: notification.description,
    purpose: notification.notification_purpose_mc_id,
    status: notification.status,
    userName: notification.action_user_user_name,
    subject: notification.verb,

    priority: notification.level,

    issuedOn: notification.timestamp ? moment(notification.timestamp) : '',
    respondBy: notification.event_date ? moment(notification.event_date) : '',

    isSeenClosure: notification.is_seen_closure,
    isUnresolved: notification.is_unresolved,
    isSeenRequired: notification.is_seen_required,

    sender: notification.sender_user_name,
    seenDate: notification.seen_date ? moment(notification.seen_date) : '',

    action: {
      type: notification.action_object.object_type,
      id: notification.action_object.object_id,
    },

    snoozeUntil: notification.remind_on ? moment(notification.remind_on) : '',
    snoozeComment: notification.remind_comment,
  };
}

function transformRequest(notification) {
  return {
    notification: {
      remind_on: notification.snoozeUntil,
      remind_comment: notification.snoozeComment,
      seen_date: notification.seenDate,
    },
  };
}

async function put(notificationId, notification) {
  const url = `api/notification/${notificationId}`;
  const requestData = transformRequest(notification);
  await axios.put(url, requestData);
  return notificationId;
}

async function getAll(params, excludeBookings) {
  const url = excludeBookings
    ? 'api/notification_without_bookings'
    : 'api/notification';
  const response = await axios.get(url, { params });
  return {
    data: {
      count: response.data.count,
      results: response.data.results.map(transformResponse),
    },
  };
}

async function get(notificationId) {
  const url = `api/notification/${notificationId}`;
  const response = await axios.get(url);
  return transformResponse(response.data.notification);
}

async function markAsSeen(notificationId) {
  const url = `api/notification/${notificationId}/seen`;
  return axios.put(url, {});
}

export { transformResponse, transformRequest, put, get, markAsSeen, getAll };
