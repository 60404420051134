import { roleUtils } from 'hcms-const-utils';

const { SUP, MGT, HR, CTR, FIN } = roleUtils.DICT;

// Specified as [Read, Write, Update]
export default {
  'staff': [[HR, MGT], [HR, MGT], [HR]],
  'cw': [[CTR, SUP, HR, MGT, FIN], [HR], [HR]],
  'su': [[CTR, SUP, MGT, FIN], [CTR], [CTR]],
  'booking': [[CTR, SUP, MGT], false, [CTR]],
  'booking_change_request': [[CTR], [CTR], [CTR]],
  'booking/actual_duration': [[CTR, SUP, MGT], false, [CTR, FIN]],
  'engine': [[CTR, MGT], [CTR], [CTR]],
  'notification': [true, true, true],
  'staff/bank_details': [[HR, MGT], false, [HR]],
  'staff/leave_summary': [[HR, MGT], [HR], [HR]],
  'staff/leave': [[HR, MGT], [HR], [HR]],
  'staff/recruitment': [[HR, MGT], false, [HR]],
  'staff/employment': [[HR, MGT], [HR], [HR]],
  'staff/attachments': [[HR, MGT], [HR], [HR]],
  'staff/staff_details': [[HR, MGT], false, [HR]],
  'staff/vaccination_details': [[HR, MGT], false, [HR]],
  'staff/suspend': [[HR, MGT], [HR], [HR]],
  'staff/terminate': [[HR, MGT], [HR], [HR]],
  'pricing_plan': [[MGT, FIN], [FIN], [FIN]],
  'payslip': [[MGT, FIN], [FIN], [FIN]],
  'cw/staff_details': [[HR, MGT, FIN], [HR], [HR]],
  'cw/vaccination_details': [[HR, MGT, FIN], [HR], [HR]],
  'cw/bank_details': [[HR, MGT, FIN], [HR], [HR]],
  'cw/address': [[CTR, HR, MGT], [HR], [HR]],
  'cw/leave_summary': [true, [HR], [HR]],
  'cw/leave': [
    [CTR, HR, SUP, MGT],
    [HR, CTR],
    [HR, CTR],
  ],
  'cw/availability': [[CTR, HR, SUP, MGT], [HR, CTR], [HR]],
  'cw/training': [
    [CTR, HR, SUP, MGT],
    [HR, CTR],
    [HR, CTR],
  ],
  'cw/performance': [[CTR, HR, SUP, MGT], [HR], [HR]],
  'cw/preference': [[CTR, HR, SUP, MGT], [HR], [HR]],
  'cw/certificate': [[CTR, HR, SUP, MGT], [HR], [HR]],
  'cw/recruitment': [[CTR, HR, MGT], [HR], [HR]],
  'cw/employment': [true, [HR], [HR]],
  'cw/suspend': [true, [CTR, HR], [HR]],
  'cw/terminate': [[CTR, HR, MGT], [CTR, HR], [HR]],
  'cw/contacts': [[CTR, HR, MGT], [HR], [HR]],
  'cw/attachments': [[HR], [HR], [HR]],
  'cw/journal': [[HR], [HR], [HR]],
  'invoice': [[MGT, FIN], [FIN], [FIN]],
  'su/address': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/observations': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/medical_history': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/medication': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/medical_status': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/schedule': [[CTR, SUP, MGT, FIN], [CTR], [CTR]],
  'su/suspend': [[CTR, SUP, MGT, FIN], [CTR], [CTR]],
  'su/preference': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/cw_exception': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/care_plan': [[CTR, SUP, MGT, FIN], [CTR], [CTR]],
  'su/personal_info': [[CTR, SUP, MGT, FIN], [CTR], [CTR]],
  'su/contacts': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/attachments': [[CTR, SUP, MGT], [CTR], [CTR]],
  'su/journal': [[CTR, SUP, MGT], [CTR], [CTR]],
  'late_medication': [[CTR], [CTR], [CTR]],
  'su/fluid': [[CTR], [CTR], [CTR]],
};
