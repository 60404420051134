import { Notification } from 'element-ui';
import { getErrorMessage } from 'global-utils';

const defaultTimeout = 5000;
const errorTimeout = 9000;

const ERROR_TYPE = 'error';

const getDuration = (type) => (type === ERROR_TYPE ? errorTimeout : defaultTimeout);

const showNotification = (notification) => {
  Notification({
    duration: defaultTimeout,
    showClose: true,
    dangerouslyUseHTMLString: true,
    ...notification,
  });
};

function notify(notification, type, duration) {
  if (!notification) {
    throw new Error('Please provide notification message');
  }

  if (typeof notification === 'string') {
    showNotification({
      message: notification,
      type,
      duration: typeof duration === 'number' ? duration : getDuration(type),
    });
    return;
  }

  if (notification instanceof Error) {
    console.error(notification);
    // eslint-disable-next-line no-console
    const message = getErrorMessage(notification);
    showNotification({
      title: 'Error',
      message,
      type,
      duration: typeof duration === 'number' ? duration : 0,
    });

    // * Avoid custom and api errors
    if (notification.name !== 'Error') {
      window.setTimeout(() => {
        throw notification;
      });
    }
    return;
  }

  throw new Error('Please provide notification message');
}

const Plugin = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$notify = notify;
  },
};

export { Plugin, notify, showNotification };
export default Plugin;
