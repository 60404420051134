<template>
  <div
    class="dashboard"
    :class="{
      'sidebar-collapsed': sidebarCollapsed,
      'show-mobile-nav': mobileNav,
    }">
    <sidebar @toggle-mobile-nav="toggleMobileNav" />

    <div class="main">
      <top-navbar
        :sidebarCollapsed="sidebarCollapsed"
        @toggleSidebar="toggleSidebar"
        @toggleMobileNav="toggleMobileNav" />
      <div v-if="envWarning" class="warning-banner danger text-light text-center">
        {{ envWarning }}
      </div>
      <div class="content">
        <transition name="fade" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.meta.key"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from './SideBar.vue';
import TopNavbar from './TopNavbar.vue';

export default {
  name: 'DashboardLayout',

  components: {
    TopNavbar,
    Sidebar,
  },

  data() {
    return {
      sidebarCollapsed: false,
      mobileNav: false,
    };
  },
  computed: {
    envWarning() {
      if (process.env.VUE_APP_ENV_WARNING_VISIBLE === 'true') {
        return `${process.env.VUE_APP_ENV_NAME} Server`;
      }
      return false;
    },
  },

  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleMobileNav(value) {
      this.mobileNav = value;
    },
  },
};
</script>
<style lang="scss">
.dashboard {
  .warning-banner {
    line-height: 18px;
    margin: -9px 0;
  }
}
</style>
