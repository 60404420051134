import { titleUtils } from 'hcms-mc-utils';

import { UPDATE_USERS, UPDATE_USER, SET_PROMISE } from './mutationTypes';

const toUser = (user) => ({
  ...user,
  title: titleUtils.toLabel(user.title),
});

export default {
  [UPDATE_USERS]: (state, userList) => {
    const newUsers = Object.fromEntries(userList.map((user) => [user.userName, toUser(user)]));

    state.users = {
      ...state.users,
      ...newUsers,
    };
  },
  [UPDATE_USER]: (state, user) => {
    state.users = {
      ...state.users,
      [user.userName]: toUser(user),
    };
  },
  [SET_PROMISE]: (state, { userType, promise }) => {
    switch (userType) {
      case 'careWorkers':
        state.promise.careWorkers = promise;
        break;
      case 'serviceUsers':
        state.promise.serviceUsers = promise;
        break;
      case 'staff':
        state.promise.staff = promise;
        break;
      case 'users':
        state.promise.users = promise;
        state.promise.careWorkers = promise;
        state.promise.serviceUsers = promise;
        state.promise.staff = promise;
        break;
      default:
        break;
    }
  },
};
