import axios from 'axios';
import moment from 'moment';

function transformRequest(object) {
  return {
    data_lock: {
      object_type: object.type,
      object_id: object.id,
      user_name: object.userName,
    },
  };
}

function transformResponse(object) {
  return {
    lockedBy: object.locked_by_user_name,
    createdDate: moment(object.created_on),
    lastModifiedDate: moment(object.last_modified_on),
    type: object.object_type,
    id: object.object_id,
    userName: object.user_name,
  };
}

async function post(object) {
  const url = 'api/data_lock';
  const requestData = transformRequest(object);
  const response = await axios.post(url, requestData);
  return response.data.data_lock_id;
}

async function getAll(params) {
  const url = 'api/data_lock';
  const response = await axios.get(url, { params });
  const results = response.data.results.map(transformResponse);
  results.count = response.data.total;
  return results;
}

export { post, getAll };
