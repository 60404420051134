import { notify } from 'utils/notify';
import store from 'store';
import { setSystemTz } from 'tz-utils';

async function setDefaultTimezone() {
  try {
    const configs = await store.dispatch('getConfig', ['CLIENT_TIMEZONE']);

    const { CLIENT_TIMEZONE } = configs;

    if (!CLIENT_TIMEZONE) {
      throw new Error('Config not received');
    }
    const timezone = CLIENT_TIMEZONE.getValue();
    setSystemTz(timezone);
  } catch (error) {
    notify('Unable to retrieve valid timezone, setting system timezone as UTC', 'error', 0);
    setSystemTz();
  }
}

export default setDefaultTimezone;
