import axios from 'axios';

function transformResponse(data) {
  return {
    isRunning: data.is_running,
  };
}

async function get() {
  const url = 'api/booking_generation_status';
  const response = await axios.get(url);
  return transformResponse(response.data);
}

export { transformResponse, get };
