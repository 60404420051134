import axios from 'axios';

import { nullToEmpty } from 'global-utils';

function transformResponse(user) {
  return nullToEmpty({
    userId: user.user_id,
    userName: user.user_name,
    email: user.email,
    title: user.title_mc_id,
    profileImageId: user.profile_image_id,
    role: user.role_id,
    sex: user.sex_mc_id,
    firstName: user.first_name,
    lastName: user.last_name,
    middleName: user.middle_name,
    alias: user.alias,
    status: user.status,
    telephone: user.telephone_1,
  });
}

async function getAll(params) {
  const url = 'api/user_list';
  const response = await axios.get(url, {
    params,
  });
  return response.data.results.map(transformResponse);
}

async function get(userName) {
  const url = `api/user_list/${userName}`;
  const response = await axios.get(url);
  return transformResponse(response.data);
}

export { get, getAll, transformResponse };
